import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from "@apollo/client";
import { Autocomplete, TextField, Button, IconButton } from "@mui/material";
import { GET_ALL_USERS, GET_PROBLEM, UPDATE_ROOT_PROBLEM } from "../../Graphql/User/Query";

import { useLocation } from 'react-router-dom';
import { ReactComponent as DeleteIcon } from "../../Assets/Svgs/delete.svg";
import ToastAlert, { showToast } from "../Common/ToastAlert";
function AdminManagement() {
    const location = useLocation();
    console.log(location.state)
    const problemId = location.state?.data.id; // Assume problemId is passed via route state
    const { data: userData, loading: usersLoading, error: usersError } = useQuery(GET_ALL_USERS);
    const { data: problemData, loading: problemLoading, error: problemError } = useQuery(GET_PROBLEM, {
        variables: { userId: localStorage.getItem('userId'), problemId }
    });
    const [updateProblem] = useMutation(UPDATE_ROOT_PROBLEM);
    const [admins, setAdmins] = useState([]);

    useEffect(() => {
        if (problemData && problemData.getProblem) {
            setAdmins(problemData.getProblem.admins || []);
        }
    }, [problemData, problemLoading]);

    const [inputValue, setInputValue] = useState('');

    const handleAdminChange = (event, newAdmin) => {
        if (newAdmin && !admins.includes(newAdmin.email)) {
            setAdmins(prevAdmins => [...prevAdmins, newAdmin.email]);
            setInputValue('');  
        }
    };

    const handleDeleteAdmin = (adminEmail) => {
        setAdmins(admins.filter(email => email !== adminEmail));
    };

    const handleSaveAdmins = async () => {
        await updateProblem({
            variables: {
                userId: localStorage.getItem('userId'),
                problemId: problemId,
                data: { admins }
            }
        });
        showToast("Admins Added successfully!", "success");
   

    };

    const filteredOptions = userData?.allUsers.filter(user => !admins.includes(user.email)) || [];

    if (usersLoading || problemLoading) return <p>Loading...</p>;
    if (usersError) return <p>Error: {usersError.message}</p>;
    if (problemError) return <p>Error: {problemError.message}</p>;

    return (
        <div className="w-screen h-screen bg-img font-roboto">
                  <ToastAlert />

            <div className="text-center text-black text-xl font-bold p-4 border-b-2 broder-gray-500 ">Owner: {localStorage.getItem("username")}</div>
           <div className='w-full flex justify-center items0center space-x-5 p-5 border-b-2 broder-gray-500'> 
           <div className='flex'>
           <div className="w-[9px] h-[42px] bg-zinc-800 rounded-l-[5px]"></div>
           <h1 className="font-medium bg-[#8e6310] h-[42px] w-40 flex justify-center items-center text-center border-[1px] border-black text-white">
    Member {admins.length + 1}
  </h1>
            <Autocomplete
                options={filteredOptions}
                freeSolo
                disableClearable
                getOptionLabel={(option) => option.email}
                onChange={handleAdminChange}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                renderInput={(params) => <TextField {...params}       sx={{
                    "& .MuiOutlinedInput-input": {
                      height: "100%",
                      boxSizing: "border-box",
                    },
                  }} variant="outlined" />}
                sx={{
                    "& .MuiOutlinedInput-root": {
                      height: 42,
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    },
                    "& .MuiInputLabel-root": { lineHeight: "42px" },
                    width: 250,
                  }}
            />
             </div>
<div>
            <button className='w-[170px] h-[42px] bg-primary rounded-full shadow text-white font-medium' onClick={handleSaveAdmins}>
                Save Members
            </button>
            </div>
            </div>
            <h1 className="font-medium text-black text-xl p-3">Exising Team Members</h1>
            <div className="grid grid-cols-3 gap-x-[100px] gap-y-[50px] p-5">
                {admins.map((admin, index) => (
                    <div className='flex '>    <IconButton onClick={() => handleDeleteAdmin(admin)}>
                    <DeleteIcon style={{ color: "black" }} />
                </IconButton>
                    <div key={index} className="rounded shadow bg-[#f0ce8e] rounded-[36px] shadow-md h-[52px] flex justify-between items-center px-5  text-black w-[100%]">
                        <span>{admin}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AdminManagement;