import React, { useState, useEffect } from "react";

import Sidebar from "./SideBar";
import logo from "../../Assets/Images/logoabout.png";

const Aboutus = () => {
  const [open, setOpen] = useState(false);
  const [contentWidth, setContentWidth] = useState("79.5%");
  useEffect(() => {
    // Adjust the content width based on the sidebar's open/closed state
    setContentWidth(open ? "89.5%" : "96.5%");
  }, [open]);

  return (
    <div className="flex flex-row h-screen font-roboto bg-img text-base font-semibold">
      <div style={{ width: open ? "22%" : "60px" }}>
        <Sidebar open={open} setOpen={setOpen} />
      </div>

      <div
        className="flex flex-col  justify-start items-center"
        style={{ width: contentWidth }}
      >
        <h1 className="w-full h-[70px] bg-primary text-white text-[28px] font-medium  flex items-center pl-[100px]">
          About Us
        </h1>
        <div className="w-[90%]  flex flex-col justify-between items-center h-[85%] mt-10 ml-5 bg-[#fef7ff] p-5">
          <img src={logo} alt="" className="w-[32%] h-[50%] bg-primary" />
          <div className="w-full h-[40%] overflow-scroll scrollbar flex flex-col space-y-4">
            <h1>
              We are a team of healthcare advocates and developers of the Agile
              Nudge Plus software. Faced with the real world challenge of rising
              patient suffering due to preventable healthcare issues, we see
              firsthand how ingrained behaviors, cognitive biases and outdated
              practices create barriers to positive outcomes. The enemy is
              clear; problem behaviors in healthcare systems, including
              resistance to change, fragmented care and inefficient
              communication between stakeholders.
            </h1>
            <h1>
              Determined to make a difference, we embarked on a mission to
              create a viable solution, one that wouldn't just address
              surface-level problems but would drive fundamental behavior
              changes. However, the struggle is real. Implementing change in a
              complex adaptive system often comes with resistance from
              practitioners set in their ways, misaligned incentives and the
              sheer challenge of reaching the right stakeholders at scale.
            </h1>
            <h1>
              The drama unfolded as our team developed Agile Nudge Plus, a
              behavioral change management tool rooted in agile science. Slowly,
              we gained traction as healthcare providers begin to see the
              efficacy of implementing nudges. Through tireless iterations and
              patient advocacy, Agile Nudge has emerged as the resolution,
              transforming clinical practices and reducing human suffering
              through actionable, evidenced-based
              interventions.
            </h1>
            <h1>
              Agile Nudge Plus is proving that with the right tools, processes
              and strategy, we can reframe healthcare decisions, helping
              professionals shift from inertia to innovation.
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
