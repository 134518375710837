import { gql } from "@apollo/client";

export const GET_USER = gql`
  query UserByEmail($email: String!) {
    userByEmail(email: $email) {
      id
      username
      email
      profilepic
    }
  }
`;

// Query to fetch all users
export const GET_ALL_USERS = gql`
  query GetAllUsers {
    allUsers {
      id
      username
      email
      profilepic
    }
  }
`;

// Query to fetch a single problem by user ID and problem ID
export const GET_PROBLEM = gql`
  query GetProblem($userId: ID!, $problemId: ID!) {
    getProblem(userId: $userId, problemId: $problemId) {
      id
      name
      children {
        id
        name
        children {
          id
          name
          target
          children {
            id
            name
            children {
              id
              name
              tasks {
                status
                valueType
                id
                name
                variableType
                baseline
                goal
                termination
                variableDescription
                frequency
                successValue
                terminationValue
                successDescription
                terminationDescription
                report {
                  status
                  reportedDate
                  reportedValue
                }
              }
              ismanual
              sprinterId
            }
            goalMetric
            terminationMetric
          }
        }
      }
      startDate
      endDate
      FavTrash
      admins
      demand {
        ExecutiveSupport
        ServiceLineAdministrativeEngagement
        ClinicianEngagement
        StrengthOfSupportingEvidence
        ImpactToTheQuadrupleAim
        PotentialToScaleUp
        AdjustedScore
        Grade
      }
    }
  }
`;

// Query to fetch all problems for a specific user
export const GET_ALL_PROBLEMS = gql`
  query GetAllProblems($userId: ID) {
    getAllProblems(userId: $userId) {
      id
      name
      children {
        id
        name
        children {
          id
          name
          target
          children {
            id
            name
            children {
              id
              name
              tasks {
                status
                valueType
                id
                name
                variableType
                baseline
                goal
                termination
                variableDescription
                frequency
                successValue
                terminationValue
                successDescription
                terminationDescription
                report {
                  status
                  reportedDate
                  reportedValue
                }
              }
              ismanual
              sprinterId
            }
            goalMetric
            terminationMetric
          }
        }
      }
      startDate
      endDate
      FavTrash
      admins
      demand {
        ExecutiveSupport
        ServiceLineAdministrativeEngagement
        ClinicianEngagement
        StrengthOfSupportingEvidence
        ImpactToTheQuadrupleAim
        PotentialToScaleUp
        AdjustedScore
        Grade
      }
    }
  }
`;

// Query to fetch a single sprint by sprint ID
export const GET_SPRINT = gql`
  query GetSprint($sprintId: ID!) {
    getSprint(sprintId: $sprintId) {
      id
      name
      userId
      nudgeId
      nudgeName
      behaviorId
      problemId
      problemName
      behaviorName
      status
      stakeholderId
      sprinterName

      sprinterId
      stakeholderName
      startDate
      endDate
      tasks {
        status
        valueType

        id
        name
        variableType
        baseline
        goal
        termination
        variableDescription
        frequency
        successValue
        terminationValue
        successDescription
        terminationDescription
        report {
          status
          reportedDate
          reportedValue
        }
      }
    }
  }
`;

// Query to fetch all sprints for a specific user
export const GET_ALL_USER_SPRINTS = gql`
  query GetAllUserSprints($userId: ID!) {
    getAllUserSprints(userId: $userId) {
      id
      name
      userId
      nudgeId
      nudgeName
      problemId
      problemName
      status
      behaviorId
      behaviorName
      stakeholderId
      sprinterName
      sprinterId

      stakeholderName
      startDate
      endDate
      tasks {
        status
        valueType

        id
        name
        variableType
        baseline
        goal
        termination
        variableDescription
        frequency
        successValue
        terminationValue
        successDescription
        terminationDescription
        report {
          status
          reportedDate
          reportedValue
        }
      }
    }
  }
`;

// Query to fetch all sprints
export const GET_ALL_SPRINTS = gql`
  query GetAllSprints {
    getAllSprints {
      id
      name
      userId
      nudgeId
      nudgeName
      problemId
      problemName
      status
      behaviorId
      behaviorName
      stakeholderId
      sprinterName
      sprinterId

      stakeholderName
      startDate
      endDate
      tasks {
        status
        valueType

        id
        name
        variableType
        baseline
        goal
        termination
        variableDescription
        frequency
        successValue
        terminationValue
        successDescription
        terminationDescription
        report {
          status
          reportedDate
          reportedValue
        }
      }
    }
  }
`;

// Mutation to create a new user
export const CREATE_USER = gql`
  mutation CreateUser($username: String!, $email: String!) {
    createUser(username: $username, email: $email) {
      id
      username
      email
      profilepic
    }
  }
`;

// Mutation to create a new root problem for a specific user
export const CREATE_ROOT_PROBLEM = gql`
  mutation CreateRootProblem($userId: ID!, $data: RootProblemInput!) {
    createRootProblem(userId: $userId, data: $data) {
      id
      name
      startDate
      endDate
      FavTrash
      admins
      demand {
        ExecutiveSupport
        ServiceLineAdministrativeEngagement
        ClinicianEngagement
        StrengthOfSupportingEvidence
        ImpactToTheQuadrupleAim
        PotentialToScaleUp
        AdjustedScore
        Grade
      }
      children {
        id
        name
        children {
          id
          name
          target
          children {
            id
            name
            children {
              id
              name
              tasks {
                status
                valueType
                id
                name
                variableType
                baseline
                goal
                termination
                variableDescription
                frequency
                successValue
                terminationValue
                successDescription
                terminationDescription
                report {
                  status
                  reportedDate
                  reportedValue
                }
              }
              ismanual
              sprinterId
            }
            goalMetric
            terminationMetric
          }
        }
      }
    }
  }
`;

// Mutation to update a root problem for a specific user
export const UPDATE_ROOT_PROBLEM = gql`
  mutation UpdateRootProblem(
    $userId: ID!
    $data: RootProblemInput!
    $problemId: ID!
  ) {
    updateRootProblem(userId: $userId, data: $data, problemId: $problemId) {
      id
      name
      children {
        id
        name
        children {
          id
          name
          target
          children {
            id
            name
            children {
              id
              name
              tasks {
                status
                valueType
                id
                name
                variableType
                baseline
                goal
                termination
                variableDescription
                frequency
                successValue
                terminationValue
                successDescription
                terminationDescription
                report {
                  status
                  reportedDate
                  reportedValue
                }
              }
              ismanual
              sprinterId
            }
            goalMetric
            terminationMetric
          }
        }
      }
      startDate
      endDate
      FavTrash
      admins
      demand {
        ExecutiveSupport
        ServiceLineAdministrativeEngagement
        ClinicianEngagement
        StrengthOfSupportingEvidence
        ImpactToTheQuadrupleAim
        PotentialToScaleUp
        AdjustedScore
        Grade
      }
    }
  }
`;

// Mutation to delete a root problem for a specific user
export const DELETE_ROOT_PROBLEM = gql`
  mutation DeleteRootProblem($userId: ID!, $problemId: ID!) {
    deleteRootProblem(userId: $userId, problemId: $problemId)
  }
`;

// Mutation to generate a problem statement
export const GENERATE_PROBLEM = gql`
  mutation GenerateProblemStatement($challenge: String!) {
    generateProblemStatement(challenge: $challenge)
  }
`;

export const GENERATE_MAP = gql`
  mutation GenerateMap($challenge: String!, $problemStatement: String!) {
    generateMap(challenge: $challenge, problemStatement: $problemStatement) {
      id
      name
      children {
        id
        name
        children {
          id
          name
          target
          children {
            id
            name
            children {
              id
              name
              tasks {
                status
                valueType
                id
                name
                variableType
                baseline
                goal
                termination
                variableDescription
                frequency
                successValue
                terminationValue
                successDescription
                terminationDescription
                report {
                  status
                  reportedDate
                  reportedValue
                }
              }
            }
            goalMetric
            terminationMetric
          }
        }
      }
    }
  }
`;

// Mutation to create a new sprint
export const CREATE_SPRINT = gql`
  mutation CreateSprint($data: SprintInput!) {
    createSprint(data: $data) {
      id
      name
      userId
      nudgeId
      nudgeName
      problemId
      problemName
      status
      behaviorId
      behaviorName
      stakeholderId
      sprinterName
      sprinterId

      stakeholderName
      startDate
      endDate
      tasks {
        status
        valueType

        id
        name
        variableType
        baseline
        goal
        termination
        variableDescription
        frequency
        successValue
        terminationValue
        successDescription
        terminationDescription
        report {
          status
          reportedDate
          reportedValue
        }
      }
    }
  }
`;

// Mutation to update a sprint
export const UPDATE_SPRINT = gql`
  mutation UpdateSprint($sprintId: ID!, $data: SprintInput!) {
    updateSprint(sprintId: $sprintId, data: $data) {
      id
      name
      userId
      nudgeId
      nudgeName
      problemId
      problemName
      status
      behaviorId
      behaviorName
      stakeholderId
      sprinterName
      sprinterId

      stakeholderName
      startDate
      endDate
      tasks {
        status
        valueType

        id
        name
        variableType
        baseline
        goal
        termination
        variableDescription
        frequency
        successValue
        terminationValue
        successDescription
        terminationDescription
        report {
          status
          reportedDate
          reportedValue
        }
      }
    }
  }
`;

// Mutation to delete a sprint
export const DELETE_SPRINT = gql`
  mutation DeleteSprint($sprintId: ID!) {
    deleteSprint(sprintId: $sprintId)
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($updateUserId: ID!, $username: String!, $profilepic: String! ) {
    updateUser(id: $updateUserId, username: $username, profilepic: $profilepic) {
      id
      username
      email
      profilepic
    }
  }
`;
