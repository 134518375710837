export const nudgedata = [
    {
       "nudgetype":"Default",
       "biases":"Status Quo, Framing200b",
       "behavior":"Medical errors due defaults in electronic order templates affecting physicians' treatment decisions.  ",
       "target":"Decrease medical errors in items ordered.",
       "tasks":"Electronic physician order templates should be transitions from  defaults (options visible and preselected) to  defaults (options visible but unselected) to reduce medical errors. ",
       "Impact":"opt-out defaults resulted in more errors of commission than opt-in defaults overall, but the average opt-out error rate was 16.82 percentage points higher for low-impact errors of commission (P < 0.001, 95% CI 9.17201324.48) compared with 8.40 percentage points higher for high-impact errors of commission (P = 0.03, 95% CI 0.75201316.06)",
       "nnt":"No effect size.",
       "cohen":"Effect Size for Low Impact Errors ~ 0.62, for High Impact Errors ~ 0.32",
       "citation":"Ansher, C., Ariely, D., Nagler, A., Rudd, M., Schwartz, J., & Shah, A. (2014). Better medicine by default. Medical Decision Making, 34(2), 1472013158.",
       "esdef":"The Opt-in default nudge has a moderate to large effect size for preventing low impact errors (0.5 < d < 0.8) and a small to moderate effect size for preventing high impact errors (0.2 < d < 0.5). "
    },
    {
       "nudgetype":"Default",
       "biases":"Status Quo, Framing200b",
       "behavior":"Clinical variation and reduced patient safety specifically with respect to medication for patients in the intensive care unit. ",
       "target":"To increase the number of prescriptions of chlorhexidine mouthwash and decrease the prescription and mean volume infused of hydroxyethl starch (HES).",
       "tasks":"Changing the default prescription template where clinicians can opt out of prescribing a drug not on the chart (ie. Chlorhexidine) or can manually search for a drug not set as a default option (ie. HES). ",
       "Impact":"Treatment of Chlorhexidine: In total, 2231 ventilated patients were identified as appropriate for treatment with chlorhexidine, 591 before the intervention and 1640 after. Before the intervention, 327 591 (55.3%) patients were prescribed chlorhexidine; after the intervention, 1483 1640 (90.4%) were prescribed chlorhexidine; p<0.001 by Fisher's exact test.  HES Intervention: 1371.512005L (6302005mL patient) of HES prescribed before the intervention and 82.252005L (20.452005mL patient) after the intervention; p<0.0001 by two-tailed Student t test. The percentage of patients receiving HES fell from 54.1% to 3.1%; p<0.001 by Fisher's exact test",
       "nnt":"Effect size ~3 for chlorhexidine, ~2 for HES",
       "cohen":"No effect size. ",
       "citation":"Bourdeaux C.P., Davies K.J., Thomas M.J.C., Bewley J.S., & Gould T.H. (2014). Using 201cnudge201d principles for order set design: A before and after evaluation of an electronic prescribing template in critical care. BMJ Quality and Safety, 23(5), 3822013388.202f",
       "esdef":"Nudge must be used on 3 people for 1 person to have an improved chlorhexidine treatment. Nudge must be used on 2 people for 1 person to receive a reduction of HES."
    },
    {
       "nudgetype":"Priming, Salience, Affect",
       "biases":"Bandwagon, Loss-aversion bias, Facilitation, Anchoring, Availability, Affect",
       "behavior":"Bad hand hygiene ",
       "target":"Increased use of an alcohol-based hand rub.",
       "tasks":"Nudges were implemented as different posters that were based on two cognitive biases: (1) bandwagon effect; (2) loss aversion relative risk",
       "Impact":"The six dispensers together were used 2842 times. Both posters increased the overall use of the entrance dispenser on ward B [poster 1: relative risk: 1.61 (95% confidence interval: 1.1820132.20), poster 2: 1.72 (1.1720132.53)] but not of the entrance dispenser on ward A [poster 1: 1.04 (0.7920131.38), poster 2: 1.07 (0.8020131.42)]. ",
       "nnt":"No effect size. ",
       "cohen":"Effect size for Ward B ~ 0.10",
       "citation":"Caris, M. G., Labuschagne, H. A., Dekker, M., Kramer, M. H. H., van Agtmael, M. A., & Vandenbroucke-Grauls, C. M. J. E. (2018). Nudging to improve hand hygiene. Journal of Hospital Infection, 98(4), 3522013358.202f",
       "esdef":"Posters to encourage hand-washing can be effective in some wards and not others, having a small effect size (d < 0.2) for certain posters."
    },
    {
       "nudgetype":"Priming",
       "biases":"Priming, Facilitation",
       "behavior":"Bad hand hygiene ",
       "target":"Increased use of an alcohol-based hand rub.",
       "tasks":"Installed dispensers all throughout the hospital to provide environmental cues",
       "Impact":"With the 13 initial dispensers installed, there was a mean of 31 hand hygiene events per patient per day (upper control limit [UCL], 46; lower control limit [LCL], 17), with 51 total dispensers there was a mean of 38 hand hygiene events per patient per day (UCL, 51; LCL, 25).",
       "nnt":"No effect size.",
       "cohen":"Effect Size ~ 0.99",
       "citation":"Chan, B. P., Homa, K., & Kirkland, K. B. (2013). Effect of varying the number and location of alcohol-based hand rub dispensers on usage in a general inpatient medical unit. Infection Control & Hospital Epidemiology, 34(9), 9872013989.",
       "esdef":"Increasing the number and dispersion of alcohol-based hand rub dispensers in a healthcare facility can increase hand hygiene events with a large effect size (d > 0.8). Pairing this intervention with additional nudges may produce a larger effect size."
    },
    {
       "nudgetype":"Salience, Priming, Messenger",
       "biases":"Feedback, Authority, Facilitation, Anchoring, Availability",
       "behavior":"Inappropriate prescribing of antibiotics in pediatric care,",
       "target":"Decreased length of antibiotic treatment; Adherence to advice of experts.",
       "tasks":"Participants received detailed feedback in the form of a professional benchmark, allowing them to compare their own prescription decisions to those of the professionals.",
       "Impact":"After feedback had been provided to pediatricians in the intervention group, the number of days of antibiotic treatment changed, on average, by 20130.60 (SD, 0.97; 95% CI, 20130.91 to 20130.29).",
       "nnt":"Effect size ~10",
       "cohen":"Effect size ~0.33",
       "citation":"Eilermann, K., Halstenberg, K., Kuntz, L., Martakis, K., Roth, B., & Wiesen, D. (2019). The Effect of Expert Feedback on Antibiotic Prescribing in Pediatrics: Experimental Evidence. Medical Decision Making, 39(7), 7812013795.",
       "esdef":"Nudge must be used on 10 pediatricians in order for 1 to significantly reduce the length of the antiobiotic treatment. \n\nA nudge proving feedback and expert benchmarks has a small to moderate effect size (0.2 < d < 0.5). "
    },
    {
       "nudgetype":"Default",
       "biases":"Status Quo, Framing, Availability 200b",
       "behavior":"Inappropriate level of HO-CDI (Clostridioides difficile infection) testing.",
       "target":"Reduction of potentially inappropriate orders ",
       "tasks":"The intervention consisted of a pop-up notification that was triggered when providers entered certain orders defaulting to cancellation of the order. The pop-notification had a default 201cokay201d setting that nudged the clinician to cancel potentially inappropriate orders",
       "Impact":"Monthly HO-CDI orders decreased by 21% postintervention (level-change rate ratio [RR], 0.79; 95% confidence interval [CI], 0.73-0.86), and the rate continued to decrease (postintervention trend change RR, 0.99; 95% CI, 0.98-1.00). The intervention was not associated with a level change in inappropriate HO-CDI orders (RR, 0.80; 95% CI, 0.61-1.05), but the postintervention inappropriate order rate decreased over time (RR, 0.95; 95% CI, 0.93-0.97).",
       "nnt":"Effect size for HO-CDI ~5",
       "cohen":"Effect size for  HO-CDI ~ 0.18",
       "citation":"Howard-Anderson, J. R., Sexton, M. E., Robichaux, C., Wiley, Z., Varkey, J. B., Suchindran, S., Albrecht, B., Jones, K. A., Fridkin, S. K., & Jacob, J. T. (2020). The impact of an electronic medical record nudge on reducing testing for hospital-onset Clostridioides difficile infection. Infection Control & Hospital Epidemiology, 41(4), 4112013417.",
       "esdef":"Nudge must be used on 5 doctors in order for 1 to reduce inappropriate HO-CDI orders. \n\nNudge produces a small effect size for decreasing the median monthly HO-CDI ( d ~ 0.2). The nudge was not able to significantly decrease inappropriate ordering in the short-term but was significant over time post intervention. "
    },
    {
       "nudgetype":"Default, Salience, Priming, Commitment",
       "biases":"Status Quo, Framing, Availability, Anchoring200b",
       "behavior":"Many prescribing errors",
       "target":"Improve prescribing behavior & reducing prescribing errors.",
       "tasks":"The intervention involved the redesign of a prescription chart template based on the Mindspace framework for  nudges interventions. The chart design highlighted the following: (i) default setting for antibiotic selection; (ii) salient cues were used to reduce the potential for ambiguity; (iii) priming with an  example of how a prescription entry should look; and (iv) use of checklist as a commitment2028device",
       "Impact":"The prescription chart design intervention led to signifb01cant improvements in prescribing behavior : Medication orders on the IDEAS chart were significantly more likely to include correct dose entries (164 164 vs 166 174; p=0.0046) as well as prescriber's printed name (163 164 vs 0 174; p<0.0001),  contact number (137 164 vs 55 174; p<0.0001), and frequency of medication administration  (120 164 vs 15 174; p<0.0001).",
       "nnt":"Effect Size ~22",
       "cohen":"Effect size  ~ 0.22",
       "citation":"King, D., Jabbar, A., Charani, E., Bicknell, C., Wu, Z., Miller, G., Gilchrist, M., Vlaev, I., Franklin, B. D., & Darzi, A. (2014). Redesigning the 2018choice architecture2019 of hospital prescription charts: A mixed methods study incorporating in situ simulation testing. BMJ Open, 4(12).",
       "esdef":"Nudge must be used on 22 prescribers in order for 1 prescriber to improve prescribing behavior.\n\nThe effect size for the nudge increasing the number of correct dose entries is small (d ~ 0.2)"
    },
    {
       "nudgetype":"Default",
       "biases":"Status Quo, Framing",
       "behavior":"Addresing medication nonadherence",
       "target":"Improvement of care provided by internal medicine resident physicians.",
       "tasks":"Conducted a 3-arm randomized clinical trial among internal medicine resident physicians to examine the impact of an audit & feedback intervention on ambulatory quality measures (AQMs) - residents in all 3 groups received an email containing the contact information of a population health coordinator & list of AQM's (control) - Practice Target group received individual AQM data compared to the target AQM goals for all primary care practices - residents in each intervention group received updated information 6 months later - 10 AQMs related to diabetes care, hypertension management, lipid control, cancer screening sere examined at baseline, 6 months, & 13 months...",
       "Impact":"There were no differences between the treatment arms and control_x000B_ for the primary adherence outcome. However, the pharmacist intervention was more likely to occur when it was triggered by default - 13 months follow-up, the Practice Target group had statistically significant improvement in cervical cancer screening rate (77% vs. 65.3%) and colorectal cancer screening rate (72.5% vs. 64.6%).",
       "nnt":"Effect Size ~11 ",
       "cohen":"No effect size.",
       "citation":"Hwang A.S., Harding A.S., Chang Y., O2019Keefe S.M., Horn D.M., & Clark A.L. (2019). An audit and feedback intervention to improve internal medicine residents2019 performance on ambulatory quality measures: A randomized controlled trial. Population Health Management, 22(6), 5292013535.\n",
       "esdef":"Nudge must be used on 11 people to see 1 additional patient screened for cancer (either cervical or colorectal)."
    },
    {
       "nudgetype":"Default",
       "biases":"Status Quo, Framing",
       "behavior":"High opioid prescriptions for patients discharged from emergency departments fueling the opioid epidemic crisis. ",
       "target":"Reduced opioid prescriptions for patients.",
       "tasks":"The intervention involved a change in the default quantities for opioids from status quo quantities of 12 and 20 tablets to null, 5, 10, and 15 tablets according to a block randomization scheme. ",
       "Impact":"The intervention was associated with a decrease in the quantities of opioids prescribed: using linear regression, an increase of 0.19 tablets prescribed (95% CI, 0.15-0.22) was found for each tablet increase in default quantity when evaluating each of the 15 pairwise comparisons of default quantities (eg, 5 vs 15 tablets), a lower default was associated with a lower number of pills prescribed in more than half (8 of the 15) of the pairwise comparisons.",
       "nnt":"No effect size.",
       "cohen":"Effect Size ~ 0.16",
       "citation":"Montoy, J. C. C., Coralic, Z., Herring, A. A., Clattenburg, E. J., & Raven, M. C. (2020). Association of Default Electronic Medical Record Settings With Health Care Professional Patterns of Opioid Prescribing in Emergency Departments A Randomized Quality Improvement Study. Jama Internal Medicine, 180(4), 4872013493.",
       "esdef":"Changing default opioid prescription settings has a small effect size (d ~ 0.2). It is likely to have practical significance only in a content where a large amount of opioids are prescribed at baseline. "
    },
    {
       "nudgetype":"Default",
       "biases":"Status Quo, Framing",
       "behavior":"addressing compliance with LPV strategies during general anesthesia",
       "target":"Increase compliance with lung-protective ventilation (LPV) strategies.",
       "tasks":"The intervention involved individualized dashboards that provided audit and feedback information. The feedback included information on both provider-level_x000B_ and department-level compliance with LPV_x000B_strategies.   Additionally, the default setting on anesthesia machines for tidal volume was decreased.",
       "Impact":"The feedback and default nudges were associated with an increased compliance with lung-protective ventilation (LPV) strategies: absolute compliance rates increased from 59.3% to 87.8% preintervention to postintervention; introduction of attending physician dashboards resulted in a 41% increase in the odds of compliance (OR 1.41, 95%2009CI 1.17 to 1.69, p=0.002); addition of advanced practice provider and resident dashboards lead to an additional 93% increase in the odds of compliance; modifying ventilator defaults led to a 376% increase in the odds of compliance (OR 3.76, 95%2009CI 3.1 to 4.57, p<0.001).",
       "nnt":"Effect size ~4",
       "cohen":"Effect Size ~ 0.39",
       "citation":"O2019Reilly-Shah, V. N., Easton, G. S., Jabaley, C. S., & Lynde, G. C. (2018). Variable effectiveness of stepwise implementation of nudge-type interventions to improve provider compliance with intraoperative low tidal volume ventilation. BMJ Quality & Safety, 27(12), 100820131018.",
       "esdef":"The nudge needs be used on 4 doctors in order for 1 doctor to have improved absolute compliance with the LPV strategies.\n\nChanging the default ventilation setting had a small to moderate effect size (0.2 < d < 0.5). "
    },
    {
       "nudgetype":"Default, Framing",
       "biases":"Status Quo",
       "behavior":"Not prescribing generic medications as much as possible.",
       "target":"Maintaining the long term durability of increases in generic prescribing rates after changing EHR prescription to default settings.",
       "tasks":"The intervention involved a change in default prescription settings in the EHR system from opt-in to opt-out.",
       "Impact":"Study found that default options can lead to sustained changes in clinical behavior up to 2.5 years: sample comprised 76 medications - during 10-month pre-intervention period, 75.4% (542,961   720,318) of prescriptions were generic; during 30-month post-intervention period, 98.4% (2,558,039   2,599,045) of prescriptions were generic (P < 0.001 compared to pre-intervention period)",
       "nnt":"Effect size ~4",
       "cohen":"Effect size ~0.92",
       "citation":"Olshan, D., Rareshide, C. A. L., & Patel, M. S. (2019). Longer-Term Durability of Using Default Options in the Electronic Health Record to Increase Generic Prescribing Rates. Journal of General Internal Medicine, 34(3), 3492013350.\n",
       "esdef":"The nudge needs to impact about 4 doctors in order for 1 to increase generic prescribing rate sustained for up to 2.5 years. \n\nChanging the default HER generic prescription setting has a large effect size (d > 0.8) that is sustained for at least 2.5 years. "
    },
    {
       "nudgetype":"Default",
       "biases":"Status Quo",
       "behavior":"addressing selection of laboratory tests",
       "target":"Increase the number of order sets used for a transfusion of red blood cells platelets",
       "tasks":"The intervention involved two different types of default settings: (i) opt-out  (\"preselected\" default setting; and opt-out (\"optional\" )default setting. The default settings were implemented via a computerized physician order entry (CPOE) system ",
       "Impact":"Study found that default settings in CPOE order sets can significantly influence physician selection of laboratory tests: Posttransfusion hematocrit values were ordered for 8.3% of red cell transfusions when the default order set selection was \"off\" and for 57.4% of transfusions when the default selection was \"preselected\" (P < 0.0001). Posttransfusion platelet counts were ordered for 7.0% of platelet transfusions when the initial default order set selection was \"optional,\" increased to 59.4% when the default was changed to \"preselected\" (P < 0.0001), and then decreased to 7.5% when the default selection was returned to \"optional.\" ",
       "nnt":"Effect size ~2",
       "cohen":"Effect Size ~ 1.02",
       "citation":"Olson, J., Hollenbeak, C., Donaldson, K., Abendroth, T., & Castellani, W. (2015). Default settings of computerized physician order entry system order sets drive ordering habits. Journal of Pathology Informatics, 6.",
       "esdef":"This means that for approximately every 2 RBC transfusion orders, one additional posttransfusion hematocrit order was observed after the intervention.\n\nThe cohenfor the difference in proportions of posttransfusion hematocrit orders between the pre-intervention and post-intervention periods is very large (d>1.0)."
    },
    {
       "nudgetype":"Default",
       "biases":"Status Quo, Framing",
       "behavior":"Prescribing brand-name medications that have existing generic equivalents which contributes to unnecessary health care spending.",
       "target":"Increasing the prescription of generic medication equivalents - Beta-blockers, statins, and proton-pump inhibitors",
       "tasks":"The intervention involved changing the default settings for providers  to only display generic brands with ability to opt out. The default setting change implemented via EHR.",
       "Impact":"IM providers had an increase in generic prescribing compared with FM providers for all 3 medications combined (5.4 percentage points [95% CI, 2.2 to 8.7 percentage points]; P < 0.001), 03b2-blockers (10.5 percentage points [CI, 5.8 to 15.2 percentage points]; P < 0.001), and statins (4.0 percentage points [CI, 0.4 to 7.6 percentage points]; P = 0.002). Results for proton-pump inhibitors (2.1 percentage points [CI, 22123.7 to 8.0 percentage points]; P = 0.47) were not significant.",
       "nnt":"Effect size ~15",
       "cohen":"Effect size ~ 0.53",
       "citation":"Patel, M. S., Day, S., Small, D. S., Howell, J. T., Lautenbach, G. L., Nierman, E. H., & Volpp, K. G. (2014). Using default options within the electronic health record to increase the prescribing of generic-equivalent medications: A quasi-experimental study. Annals of Internal Medicine, 161(10 Suppl), S44-52.\n",
       "esdef":"Nudge needs to impact 15 providers in order for 1 provider to increase generic medication prescriptions.\n\nChanging the default setting for providers to display generic medications has a moderate effect size (d ~ 0.5)."
    },
    {
       "nudgetype":"Default",
       "biases":"Status Quo, Framing",
       "behavior":"Daily imaging with palliative radiotherapy",
       "target":"Reduce unecessary daily imaging during palliative radiotherapy with the default imaging order in the EHR",
       "tasks":"The intervention introduced a default imaging order in the2028 EHR that specified no daily imaging during palliative radiotherapy. Physicians could opt-out, selecting another imaging frequency.\n",
       "Impact":"Daily imaging was used in 68.2% (463 679) of courses in preintervention periods and 32.4% (165 509) of courses in intervention periods. The default intervention led to a significant reduction in daily imaging (adjusted odds ratio, 0.43; 95% CI, 0.24-0.77; adjusted difference in percentage points, 221218.6; 95% CI, 221234.1 to 22122.1; P2009=2009.004)",
       "nnt":"Effect Size ~3",
       "cohen":"Effect Size ~0.77",
       "citation":"Sharma, S., Guttmann, D., Small, D. S., Rareshide, C. A. L., Jones, J., Patel, M. S., & Bekelman, J. E. (2019). Effect of Introducing a Default Order in the Electronic Medical Record on Unnecessary Daily Imaging During Palliative Radiotherapy for Adults With Cancer: A Stepped-Wedge Cluster Randomized Clinical Trial. JAMA Oncology, 5(8), 122020131222.",
       "esdef":"The nudge needs to be used on 3 radiation oncologists for 1 patient to see a decrease in daily imaging.\n\nChanging the default setting so clinicans had to opt out of no daily imaging has a large effect size (d ~ 0.8) and is likely to have practical significance."
    },
    {
       "nudgetype":"Priming",
       "biases":"Framing, Priming",
       "behavior":"PVC-related complications and RNs2019 not adhereing to the guideline recommendation.",
       "target":"Increasing registered nurses (RNs) self-reported adherence to clinical guidelines and decrease PVC-related complications.",
       "tasks":"PVC clinical practice guideline reminders intergrated into the electronic patient records. They appeared for 20 seconds when the pointer was over the template.",
       "Impact":"The complication rate at baseline and post-intervention was 40.6 % (95 % confidence interval (CI) 36.7201344.5) and 41.9 % (95 % CI 38.0201345.8), for the intervention group and 40.3 % (95 % CI 36.8201344.0) and 46.9 % (95 % CI 43.1201350.7) for the control. ",
       "nnt":"No effect size. ",
       "cohen":"Effect Size ~ 0.01",
       "citation":"F00f6rberg, U., Unbeck, M., Wallin, L., Johansson, E., Petzold, M., Ygge, B.-M., & Ehrenberg, A. (2015). Effects of computer reminders on complications of peripheral venous catheters and nurses2019 adherence to a guideline in paediatric care2014A cluster randomised study. Implementation Science, 11(1), 10.",
       "esdef":"The effect size for the reminder nudges for nurses is very small compared to other applicable nudges (d < 0.2)."
    },
    {
       "nudgetype":"Salience, Priming",
       "biases":"Framing, Priming, Loss Aversion Bias, Feedback",
       "behavior":"Unecessary TTE (transthoracic echocardiography) testing ",
       "target":"Reducing proportion of unecessary & inappropriate TTE & increase proportion of appropriate TTE ",
       "tasks":"The intervention consisted of: 1) a lecture to house staff on the 2011 AUC for TTE; 2) a pocket card that applied the AUC to common clinical scenarios; and 3) biweekly e-mail feedback regarding ordering behavior.",
       "Impact":"During study period, there was a 26% reduction in the # of TTE ordered per day compared with the # ordered during control period (2.9 vs. 3.9 TTE, p < .001) - proportion of appropriate TTE was significantly higher (93% vs 84%, p < 0.001)",
       "nnt":"Effect size ~4",
       "cohen":"No effect size",
       "citation":"Bhatia, R. S., Milford, C. E., Picard, M. H., & Weiner, R. B. (2013). An educational intervention reduces the rate of inappropriate echocardiograms on an inpatient medical service. Jacc: Cardiovascular Imaging, 6(5), 5452013555.",
       "esdef":"Nudge needed to be used on 4 staff member  in order for 1 staff member to reduce unecessary TTE testing."
    },
    {
       "nudgetype":"Salience, Priming",
       "biases":"Framing, Priming, Availability, Facilitation",
       "behavior":"Low adherence to local MRSA guidelines for patients undergoing emergency hip fracture surgery - eradication therapy & antibiotics",
       "target":"Increasing adherence to clinical MRSA guidelines for patients undergoing emergency surgery - hip fracture surgery",
       "tasks":"The interventions included informative point-of-decision prompts in the form of posters and stickers were placed in anesthetic rooms",
       "Impact":"The case-mix was similar in both audit cycles: initial audit (n = 69, mean age 76.9, range 33201394), re-audit (n = 77, mean age 73.8, range 18201395). There was a small rise in adherence to eradication therapy prescription (1% vs 8%) after sticker distribution, although compliance was poor pre and post intervention. Correct antibiotic choice for MRSA unknown patients (18% vs 94%) improved significantly after poster intervention. ",
       "nnt":"Effect Size ~ 1",
       "cohen":"No effect size",
       "citation":"Bodansky, D., Oskrochi, Y., Judah, G., Lewis, M., Fischer, B., & Narayan, B. (2017). Change the habit to change the practice: Do audits really ever change anything? Injury, 48(9), 199920132002.",
       "esdef":"The sticker distribution nudge had little impact. but the poster nudge was effective; nudge needed to be seen in 3 rooms for doctors to make the correct antiobiotic choice for 4 patients."
    },
    {
       "nudgetype":"Salience, Priming",
       "biases":"Framing, Priming, Loss Aversion Bias, Feedback",
       "behavior":"Inconsistent adherence to guidelines by prescribers",
       "target":"Increasing adherence to pharmacotherapeutic ordering guidelines",
       "tasks":"Educational program covering a variety of relevant topics used in combination with feedback that was based on the medication reviews for the prescriber.",
       "Impact":"Non-adherence was observed significantly less often during the intervention period (21.8% (193 886)] as compared to the usual care period [30.5% (332 1089)]",
       "nnt":"Effect Size ~12 ",
       "cohen":"Effect Size ~ 0.20",
       "citation":"Bos, J. M., Natsch, S., van den Bemt, P. M. L. A., Pot, J. L. W., Nagtegaal, J. E., Wieringa, A., van der Wilt, G. J., De Smet, P. A. G. M., & Kramers, C. (2017). A multifaceted intervention to reduce guideline non-adherence among prescribing physicians in Dutch hospitals. International Journal of Clinical Pharmacy, 39(6), 121120131219.",
       "esdef":"Nudge needed to be used on 12 prescribers in order for 1 prescriber to increase adherence to pharmacotherapeutic ordering guidelines.\n\nThere is a small effect size (d ~ 0.2) for reducing o non-adherence between the intervention and usual care periods."
    },
    {
       "nudgetype":"Salience, Incentives, Priming",
       "biases":"Anchoring, Framing, Incentives, Facilitation",
       "behavior":"Low adherence to ASP program",
       "target":"Increasing physicians' adherence to institutional antibiotic stewardship program (ASP) ",
       "tasks":"Intervention included a monthly educational meeting as well as a best practice advisory (BPA) alert implemented in the CDS and HER system that would prompt physicians to use an electronic order set",
       "Impact":"Total of 227 patients were included, 111 in pre-intervention & 116 in post-intervention period; completion of all 6 bundle elements improved by 27.2% in post-intervention group (29.7% vs 56.9%; P < 0.001); outcome of 30 day mortality or 90 day readmission with SAB complication decreaed in post-intervention group by 9.6% (24.3% vs. 14.7%; P = 0.092)",
       "nnt":"Effect size ~4",
       "cohen":"Effect Size ~ 0.55",
       "citation":"Brotherton, A. L., Rab, S., Kandiah, S., Kriengkauykiat, J., & Wong, J. R. (2020). The impact of an automated antibiotic stewardship intervention for the management of Staphylococcus aureus bacteraemia utilizing the electronic health record. Journal of Antimicrobial Chemotherapy, 75(4), 105420131060.",
       "esdef":"Nudge needed to be used on 4 physicians in order for 1 physician to increase adherence to all 6 bundle elements.\n\nThere was a moderate effect size (d ~ 0.5) for the difference in proportions of completion of all six bundle elements between the pre-intervention and post-intervention periods."
    },
    {
       "nudgetype":"Priming, Salience, Incentives",
       "biases":"Visual Imagery, Framing, Availability",
       "behavior":"Low adherence to guideline recommendations for OM",
       "target":"Increasing adherence to guideline recommendations for OM pain management, diagnositc documentation, and medication management",
       "tasks":"Intervention involved a 4-arm cluster randomized trial. The 4 groups were CDS with feedback, CDS only, feedback only, and usual care (control). Subjects in the CDS group were provided access to data entry tools; cognitive aids, visual information display and workflow aids via a clinical decision support (CDS) system; those in the feedback group received information showing physicians' adherence to guidelines for the month, change over time, and comparisons with others in their practice and the network; and those in the CDS with  feedback group received a  combination of two treatments.",
       "Impact":"Increase from baseline to intervention periods in adherence to guidelines was larger for CDS compared with non-CDS visits for comprehensive care, pain treatment, adequate diagnositc evaluation for OME, an amoxicillin as first-line therapy for AOM; performance feedback was associated with improved antibiotic prescribing for AOM and pain treatment 8.9 (p =.005).",
       "nnt":"Effect Size ~ 29",
       "cohen":"No effect size.",
       "citation":"Forrest, C. B., Fiks, A. G., Bailey, L. C., Localio, R., Grundmeier, R. W., Richards, T., Karavite, D. J., Elden, L., & Alessandrini, E. A. (2013). Improving Adherence to Otitis Media Guidelines With Clinical Decision Support and Physician Feedback. Pediatrics, 131(4), E10712013E1081.",
       "esdef":"For every approximately 29 patients treated, feedback increased adherence to guidelines compared to  CDS-Only practice.  "
    },
    {
       "nudgetype":"Salience, Priming",
       "biases":"Framing, Priming",
       "behavior":"Low use of anticoagulants in primary care",
       "target":"Increasing use of anticoagulants in primary care",
       "tasks":"The intervention consisted of quality reports that detailed information about each center's use of recommended medications as well as information showing whether patients with AF using anticoagulants in each center was higher or lower than the county mean proportion",
       "Impact":"use of anticoagulants in all primary care centers increased from 76% before to 82% after the intervention - patients in intervention centers were more likely than patients in control centers to use anticoagulants after the intervention, adjusted odds ratio increased slightly from 1.04 before to 1.08 after the intervention",
       "nnt":"Effect size ~17",
       "cohen":"Effect Size ~ 0.03",
       "citation":"Geary, L., Hasselstr00f6m, J., Carlsson, A. C., Schenck-Gustafsson, K., & von Euler, M. (2020). An audit & feedback intervention for improved anticoagulant use in patients with atrial fibrillation in primary care. International journal of cardiology, 310, 67201372.",
       "esdef":"Nudge needed to be used on 17 individuals in order for 1 doctor to increase anticoagulant use.\n\nThe nudge had a very small effect size (d << 0.2) on use of anticoagulants by patients."
    },
    {
       "nudgetype":"Priming, Salience",
       "biases":"Framing, Anchoring, Facilitation",
       "behavior":"Low influenza vaccination rates",
       "target":"Increaseing influenza vaccination rates through an active choice intervention in the electronic health record (EHR)",
       "tasks":"The intervention involved a prompt for the physician and their medical assistant to actively choose to accept or cancel an order for influenza vaccine for eligible patients. The intervention was implemented via an EHR system.",
       "Impact":"Sample was 62.9% female, 35.9% white, & 54.4% black, with a mean age of 50.2 years. Vaccination rates increased in both groups in the post-intervention year, but the intervention practice using active choice had a significantly greater increase than the control, representing a 37.3% relative increase compared to pre-intervention period",
       "nnt":"Effect size ~3",
       "cohen":"Effect Size ~ 0.49",
       "citation":"Patel, M. S., Volpp, K. G., Small, D. S., Wynne, C., Zhu, J., Yang, L., Honeywell, S., & Day, S. C. (2017). Using Active Choice Within the Electronic Health Record to Increase Influenza Vaccination Rates. Journal of General Internal Medicine, 32(7), 7902013795.",
       "esdef":"Nudge needed to be used on 3 physicians in order for 1 physician to increase their vaccination rate through HER.\n\nThere is a moderate effect size for the increase in vaccination rates (d ~ 0.5)."
    },
    {
       "nudgetype":"Priming, Salience",
       "biases":"Facilitation, Feedback",
       "behavior":"Poor adherence to prescribing guidelines for common bacterial ARTIs",
       "target":"Improving adherence to prescribing guidelines for common bacterial ARTIs through antimicrobial stewardship intervention ",
       "tasks":"The intervention included (1) clinician education, comprising a 1-hour review of current prescribing guidelines for the targeted conditions & (2) audit and feedback of antibiotic prescribing",
       "Impact":"Broad-spectrum antibiotic prescribing decreased from 26.8% to 14.3% among intervention practices vs from 28.4% to 22.6% in controls. Off-guideline prescribing for children with pneumonia decreased from 15.7% to 4.2% among intervention practices compared with 17.1% to 16.3% in controls. Off-guideline prescribing was uncommon at baseline and changed little for streptococcal pharyngitis (intervention, from 4.4% to 3.4%; control, from 5.6% to 3.5%)",
       "nnt":"Effect size ~8",
       "cohen":"No effect size.",
       "citation":"Gerber, J. S., Prasad, P. A., Fiks, A. G., Localio, A. R., Bell, L. M., Keren, R., & Zaoutis, T. E. (2014). Durability of benefits of an outpatient antimicrobial stewardship intervention after discontinuation of audit and feedback. JAMA, 312(23), 256920132570.",
       "esdef":" Nudge needed to be used on 8 prescribers in order for 1 prescriber to improve adherence to prescription guidelines for bacterial ARTIs. However, the nudge did not change behavior post-intervention."
    },
    {
       "nudgetype":"Priming, Salience, Messenger",
       "biases":"Facilitation, Feedback, Mindlessness",
       "behavior":"Low HPV vaccination rates",
       "target":"Improve the reach and impact of strategies for HPV vaccination quality improvement (QI) through local adaptation and implementation in a large, not-for-profit health care system",
       "tasks":"Intervention consisted of in-clinic training session in which physicians received individual assessment and feedback on HPV vaccination coverage",
       "Impact":" Of 45 physicians in the QI arm, the program reached 43 (95%) with training plus assessment and feedback. In the overall sample, HPV vaccination coverage increased in both the QI and control arms (8.6 vs 6.4 percentage points, respectively), although the 2.2-percentage point difference did not reach statistical significance",
       "nnt":"No effect size.",
       "cohen":"No effect size.",
       "citation":"Gilkey M.B., Parks M.J., Margolis M.A., McRee A.-L., & Terk J.V. (2019). Implementing evidence-based strategies to improve HPV vaccine delivery. Pediatrics, 144(1).",
       "esdef":"The study did not have the data to properly calculate effect size as the data recovered was not statistically significant."
    },
    {
       "nudgetype":"Priming, Salience",
       "biases":"Framing",
       "behavior":"Low adherence to health and safety behaviors",
       "target":"Increase health & safety behaviors - specifically had hygiene of health care professionals",
       "tasks":"The intervention consisted of 3 types of messages on hand-hygiene behaviors of health care professionals in a U.S. hospital. That is, study had 3 comparator groups based on messages that emphasized: (i) personal consequences; (ii) patient consequences; & (iii) neither",
       "Impact":"hand-hygiene adherence increased significantly on units with the patient-consequences sign (80.69% to 89.20%), 03c72(1, N = 378) = 5.25, p = .02, d = 0.33, prep = .93. In contrast, hand-hygiene adherence did not change significantly on units with the personal-consequences sign (80.00% to 79.71%)",
       "nnt":"Effect Size ~ 12",
       "cohen":"Effect Size ~ 0.65",
       "citation":"Grant, A. M., & Hofmann, D. A. (2011). It2019s not all about me: Motivating hand hygiene among health care professionals by focusing on patients. Psychological Science, 22(12), 149420131499.",
       "esdef":"Nudge needed to be used on 12 practioners in order for 1 practioner to improve their hand-hygiene adherance.\n\nModerate to large effect size (0.5 < d < 0.8) between controls and patient consequence messages nudge."
    },
    {
       "nudgetype":"Priming, Salience",
       "biases":"Framing, Feedback",
       "behavior":"uneccesary antibiotic prescribing - contributes to antimicrobial resistance",
       "target":"Reduce antibiotic prescribing",
       "tasks":"The intervention consisted of information that communicated whether a practice was prescribing antibiotics at a higher rate than 80% of practices in its locality.",
       "Impact":"Between October, 2014, and March, 2015, the rate of antibiotic items dispensed per 1000 population was 126.98 in the feedback intervention group and 131.25 in the control group, a difference of 4.27, representing an estimated 73,406 fewer antibiotic items dispensed. Antibiotic items dispensed per 1000 population: Control  (n=401) 61.35 (13.63) v Feedback intervention  (n=402) 60.67 (11.63).",
       "nnt":"Effect size ~234",
       "cohen":"Effect Size ~ 0.05",
       "citation":"Hallsworth, M., Chadborn, T., Sallis, A., Sanders, M., Berry, D., Greaves, F., Clements, L., & Davies, S. C. (2016). Provision of social norm feedback to high prescribers of antibiotics in general practice: A pragmatic national randomised controlled trial. The Lancet, 387(10029), 174320131752.",
       "esdef":"The effect size is for the intervention. Nudge needed to be used on 234 people in order for 1 person to reduce antiobiotic prescribing.\n\nThe effect size of this nudge is very small, must be used on very large samples to have any observable results. "
    },
    {
       "nudgetype":"Priming, Salience",
       "biases":"Framing",
       "behavior":"Poor hand hygiene among health care workers",
       "target":"Increase hand hygiene among health care workers",
       "tasks":"The intervention consisted of gain-framed messages implemented via computer screens",
       "Impact":"The negative trend in hand hygiene events per patient-day before the intervention changed to a significant positive trend (increase of 1.5 [0.5] per week) after the intervention (P < 0.001). Results confirmed a significant improvement in hand hygiene compliance from 193 of 303 (63.6%) observed hand hygiene events at pretest to 201 of 281 (71.5%) at posttest",
       "nnt":"Effect size ~13",
       "cohen":"Effect Size ~ 1.65",
       "citation":"Helder, O. K., Weggelaar, A. M., Waarsenburg, D. C., Looman, C. W., van Goudoever, J. B., Brug, J., & Kornelisse, R. F. (2012). Computer screen saver hand hygiene information curbs a negative trend in hand hygiene behavior. American Journal of Infection Control, 40(10), 9512013954.",
       "esdef":"Nudge needed to be used on 13 health care workers in order for 1 health care worker to increase their hand hygeine.\n\nThis nudge generates a large effect size, suggesting a substantial improvement in hand hygiene compliance from prior to the intervention. "
    },
    {
       "nudgetype":"Priming, Salience",
       "biases":"Framing, Priming, Loss Aversion Bias, Feedback",
       "behavior":"Poor antibiotic prescribing in patients with community-acquired pneumonia (CAP) and acute exacerbations of chronic obstructive pulmonary disease (AECOPD)",
       "target":"Promote appropriate antibiotic prescribing - guideline-recommended antibiotic prescribing",
       "tasks":"A pre-intervention audit of compliance with guideline recommended antibiotic prescribing was performed. The intervention consisted of oral feedback on the results of the pre-intervention audit as well as distribution of pocketbook guidelines. Feedback was provided orally to the department physicians at an internal-educational meeting",
       "Impact":"Following intervention, overall mean prescribing of appropriate empirical antibiotics increased from 61.7% to 83.8%, overall mean total treatment duration decreased from 11.2 to 10.4 days, and prescribing high-dose benzylpenicillin decreased from 48.8% to 38.6%. Combination of audit and feedback plus distribution of a pocket version of guideline recommendations led to a substantial increase in presctibing appropriate empirical antibiotics",
       "nnt":"Effect size ~5",
       "cohen":"No Effect Size",
       "citation":"H00f8gli, J. U., Garcia, B. H., Skjold, F., Skogen, V., & Sm00e5brekke, L. (2016). An audit and feedback intervention study increased adherence to antibiotic prescribing guidelines at a Norwegian hospital. BMC Infectious Diseases, 16(1).",
       "esdef":" Nudge needed to be used on 5 prescribers in order for 1 prescriber to appropriately follow the recommended guidelines for antibiotic prescribing. However, the nudge changed behavior post-intervention for six months."
    },
    {
       "nudgetype":"Priming, Salience",
       "biases":"Facilitation, Authority, Anchoring",
       "behavior":"Poor adherence to SCCM guidelines",
       "target":"Improve adherence to the SCCM guidelines (Society for Critical Care Medicine)",
       "tasks":"The intervention included (1) educational presentations that provided review of guidelines, discussed practices, and made recommendations (2) periodic educational emails sent to the faculty (3) two changes to ED workflow: (i) fentanyl infusions were stocked in the ED. (ii) implementation a medication order set that nudged physicians to choose medications consistent with clinical guidelines",
       "Impact":"In 2014, prior to interventions, 41% of mechanically ventilated patients received an opioid, either as an intravenous (IV) push or IV infusion. In 2015 immediately after the intervention, 71% of patients received an opioid and 64% received an opioid in 2016. The use of benzodiazepine infusions decreased from 22% in 2014 to 7% in 2015 to 1% in 2016.",
       "nnt":"Effect size ~3",
       "cohen":"Effect Size ~ 1.01",
       "citation":"Isenberg, D. L., Kissman, K. M., Salinski, E. P., Saks, M. A., & Evans, L. B. (2018). Simple Changes to Emergency Department Workflow Improve Analgesia in Mechanically Ventilated Patients. The western journal of emergency medicine, 19(4), 6682013674. https: doi.org 10.5811 westjem.2018.4.36879",
       "esdef":" Nudge needed to be used on 4 people in order for 1 person to adhere to SCCM guidelines.\n\nThe nudge generates a large effect size immediately after the intervention, which only seems to increase as years progress. "
    },
    {
       "nudgetype":"Priming",
       "biases":"Anchoring, Availability, Facilitation",
       "behavior":"Poor recognition of chronic kidney disease",
       "target":"Improve recognition of chronic kidney disease - evaluating impact of published guidelines and prompted widespread metric reporting on physicians' lab test ordering behavior",
       "tasks":"The intervention included: (1) Publication of the Kidney Disease Outcomes Quality Initiative guidelines to use the estimated glomerular filtration rate (eGFR) measurements and minimize the use of timed urine creatinine clearance collection (2) Introduction of widespread metric reporting with prompts on physician ordering of the 24-hour urine collection for creatinine clearance",
       "Impact":"Clinical practice guidelines did not influence creatinine clearance testing; however, the direct introduction of eGFR reporting with prompts into physician workflow resulted in a sudden and significant 23.5% decrease in creatinene clearance collection over the 43 months analyzed.",
       "nnt":"Effect size ~4",
       "cohen":"No effect size.",
       "citation":"Kagoma, Y. K., Garg, A. X., Li, L., & Jain, A. K. (2012). Reporting of the estimated glomerular filtration rate decreased creatinine clearance testing. Kidney International, 81(12), 124520131247.",
       "esdef":"Nudge needed to be used on 4 physicians in order for 1 physician to improve their recognition of chronic kidney disease."
    },
    {
       "nudgetype":"Salience, Incentives",
       "biases":"Anchoring, Availability, Feedback, Authority",
       "behavior":"Poor adherence to hand hygiene in the neonatal intensive care unit as a potential source of infections",
       "target":"Increase hand hygiene compliance ",
       "tasks":"The intervention package consisted of educational presentation on hand hygiene, visual reminders customized with patient staff photographs, hand swab test to show bacterial contamination, and feedback on own and peer progress in the training",
       "Impact":"Hand hygiene compliance rose from 67% to 92% overall, including a 36% increase during the night shifts-a group of healthcare workers with typically very low levels of compliance",
       "nnt":"Effect size ~4",
       "cohen":"No effect size. ",
       "citation":"Kallam, B., Pettitt-Schieber, C., Owen, M., Agyare Asante, R., Darko, E., & Ramaswamy, R. (2018). Implementation science in low-resource settings: Using the interactive systems framework to improve hand hygiene in a tertiary hospital in Ghana. International Journal for Quality in Health Care, 30(9), 7242013730.",
       "esdef":"Nudge needed to be used on 4 healthcare workers in order for 1 healthcare worker to improve their hand hygeine."
    },
    {
       "nudgetype":"Messenger, Priming",
       "biases":"Anchoring, Availability, Authority",
       "behavior":"Suboptimal vaccination rates",
       "target":"Increase vaccination rates",
       "tasks":"The active choice intervention implemented in the electronic health record systems prompted medical assistants to ask patients about influenze vaccination during check-in and template vaccination orders for clinicains to review during the visit. 3 of 11 primary practices were assigned to the intervention arm of the study, while the remaining 8 practices were assigned to the control arm",
       "Impact":" For the 3 years, vaccination rates were 46.9%, 47.2%, and 45.6% at control practices and 49.7%, 52.2%, and 59.3% at intervention practices, respectively. In adjusted analyses, compared with control practices over time, the active choice intervention was associated with a significant 9.5-percentage point increase in vaccination rates (95% CI, 4.1-14.3; P < .001). ",
       "nnt":"Effect size ~14",
       "cohen":"No effect size.",
       "citation":"Kim, R. H., Day, S. C., Small, D. S., Snider, C. K., Rareshide, C. A. L., & Patel, M. S. (2018). Variations in Influenza Vaccination by Clinic Appointment Time and an Active Choice Intervention in the Electronic Health Record to Increase Influenza Vaccination. JAMA Network Open, 1(5).",
       "esdef":" Nudge needed to be used on about 14 medical assistants in order for 1 patient to see an increase in influenza vaccination."
    },
    {
       "nudgetype":"Priming",
       "biases":"Anchoring, Availability",
       "behavior":"Poor hand hygiene compliance",
       "target":"Increase hand hygiene compliance ",
       "tasks":"Interventions included either an olfactory prime (clean, citrus smell) or visual prime (male or female eyes) placed over a hand gel dispenser",
       "Impact":"At a 5% level, there was significant evidence that a clean, citrus smell significantly improves HHC (46.9% vs 15.0%, p = 0.0001). Compared to the control group, a significant improvement in HHC was seen when a picture of \"male eyes\" was placed over the hand gel dispenser (33.3% vs 15.0%, p < 0.038). No significant improvement in HHC was seen when a picture of female eyes was placed over the same hand gel dispenser (10.0% vs 15.0%, p .626).",
       "nnt":"Effect size ~3",
       "cohen":"No effect size.",
       "citation":"King, D., Vlaev, I., Everett-Thomas, R., Fitzpatrick, M., Darzi, A., & Birnbach, D. J. (2016). 201cPriming201d hand hygiene compliance in clinical environments. Health Psychology, 35(1), 96.",
       "esdef":"Olfactory priming nudge needed to be used on about 3 health professional in order for 1 health professional to increase hand hygiene complience. There were no significant effects for visual priming nudges."
    },
    {
       "nudgetype":"Priming",
       "biases":"Anchoring, Availability",
       "behavior":"Poor adherence to a quality assessment scheme for point-of-care testing across primary care practices",
       "target":"Improve adherence to a quality assessment scheme for point-of-care testing in general practice. ",
       "tasks":"The intervention consisted of sending computer reminders (ComRem) to practices not adhering to the guideline recommendations of split testing for hemoglobin and glucose",
       "Impact":"A total of 142 practices were randomly allocated to the early intervention group and 144 practices to the late intervention group. In the first intervention period, the mean number of split tests performed in the group receiving ComRem group increased from 1.22 to 3.76 while the mean number of split tests increased from 1.11 to 2.35 in the group targeted by GIA only. After the crossover, a similar effect of reminders was observed. Furthermore, the developments in outcome measures over time showed a strong effect of computer reminders beyond the intervention periods",
       "nnt":"No effect size.",
       "cohen":"Effect Size ~ 0.30",
       "citation":"Kousgaard, M. B., Siersma, V., Reventlow, S., Ertmann, R., Felding, P., & Waldorff, F. B. (2013). The effectiveness of computer reminders for improving quality assessment for point-of-care testing in general practice2014A randomized controlled trial. Implementation Science, 8(1), 47.",
       "esdef":"There was improved adherence to the quality assessment scheme during the intervention, with small to moderate effect size (0.2 < d < 0.5). "
    },
    {
       "nudgetype":"Priming",
       "biases":"Anchoring, Availability",
       "behavior":"Poor compliance with hand hygiene",
       "target":"Improve compliance with hand hygiene",
       "tasks":"The intervention included training nurse unit managers from to access the previous 24-hour compliance rate (to hang hygiene), to share rates with clinicians at the morning clinical hand over meetings, to set future compliance goals, and to nudge each other with a reminder to hand hygiene, with a friendly \"take a moment\" as they entered a patient's room. The intervention was refreshed with reminders at the hand over meetings and seminars throughout this phase to nudge.",
       "Impact":"During the intervetion phase, average compliance did not change on the medical ward from their covert rate, whereas the surgical ward improved compared with the covert phase by 11 PPs to 60%. After 5 months, the average rate on the surgical ward was 60%, 7 PPs higher than the phase 1 average (53%). On the medical ward, the average rates for phase 2 (30%) and phase 1 (31%) were similar.",
       "nnt":"Effect Size Surgical Ward ~15, Medical Ward ~ 100",
       "cohen":"No effect size. ",
       "citation":"Kwok, Y. L. A., Juergens, C. P., & McLaws, M.-L. (2016). Automated hand hygiene auditing with and without an intervention. American Journal of Infection Control, 44(12), 147520131480.",
       "esdef":"For surgical wards, the nudge had to be used on 15 people to see 1 with increased intervention compliance. There was no significant benefit in medical wards."
    },
    {
       "nudgetype":"Priming, Salience",
       "biases":"Feedback, Facilitation, Authority",
       "behavior":"High total expenditure",
       "target":"Decrease weekly expenditure and sustained institutional expenditure.",
       "tasks":"The intervention was implemented in an electronic prescribing and administration software. The software shows the cost of the selected drug as well as new guidelines for use of cheaper inhaled corticosteroids",
       "Impact":"Mean weekly expenditure on antibiotics per patient decreased by 3.75 (95% confidence intervals [Cl] -6.52 to -0.98) after the intervention from a pre-intervention mean of 26.44, and then slowly increased subsequently by 0.10 week. ",
       "nnt":"No effect size.",
       "cohen":"Effect Size ~ 0.36",
       "citation":"Langley, T., Lacey, J., Johnson, A., Newman, C., Subramanian, D., Khare, M., Skelly, R., Norwood, M., Sturrock, N., & Fogarty, A. W. (2018). An evaluation of a price transparency intervention for two commonly prescribed medications on total institutional expenditure: A prospective study. Future Healthcare Journal, 5(3), 1982013202.",
       "esdef":"The average weekly expenditure decreased during the intervention with a small to moderate effect size (0.2 < d < 0.5). Results were not sustained post-intervention."
    },
    {
       "nudgetype":"Priming, Salience, Messenger",
       "biases":"Facilitation, Authority, Anchoring",
       "behavior":"Overuse of CT",
       "target":"Judicious use of CT",
       "tasks":"A message was added at the bottom of all in & outpatient paper electronic CT reports. Message highlighted type of patient who is at risk after exposure to ionizing radiaiton in order to nudge them to alternative treatments.",
       "Impact":"In the final parsimonious model, there were 29% fewer CT carried out in RDH(intervention institution) than NUH  (control instiution) pre-intervention (p<0.001), and a significant increasing trend in the number of CT at 0.1% increase per week in both hospitals. However, there was a statistically significant reduction in level of CT in RDH compared to NUH post-intervention of 4.6%",
       "nnt":"Effect size ~22",
       "cohen":"No effect size. ",
       "citation":"Lewis, S., Young, B., Thurley, P., Shaw, D., Cranwell, J., Skelly, R., Langley, T., Norwood, M., Sturrock, N., & Fogarty, A. (2019). Evaluation of a nudge intervention providing simple feedback to clinicians of the consequence of radiation exposure on demand for computed tomography: A controlled study. Clinical Medicine, 19(4), 2902013293.",
       "esdef":"Nudge needed to be used on about 22 people in order for 1 person to reduce CT usage."
    },
    {
       "nudgetype":"Messenger, Priming, Affect",
       "biases":"Facilitation, Affect",
       "behavior":"Poor adherence to treatment guidelines for outpatient paediatric malaria in Kenya",
       "target":"Correct management with artemether-lumefantrine, defined as a dichotomous composite indicator of treatment, dispensing, and counselling tasks concordant with Kenyan national guidelines",
       "tasks":"Health facilities were randomly allocated to either the intervention group, in which all health workers received text messages on their personal mobile phones on malaria case management for 6 months, or the control group, in which health workers did not receive any text messages; text messages contained a maximum of 160 characters, including a unique quote to each worker. For example: \"Check ALL sick children <5 years for any severe signs! Also check for ever, cough, diarrhea, pallor & any other behavior. Quote: 'Persistent work triumphs.'\"",
       "Impact":"Intention-to-treat analysis showed that correct artemether-lumefantrine management improved by 23.7 percentage-points (95% CI 700b76-4000b70; p=000b7004) immediately after intervention and by 24.5 percentage-points (800b71-4100b70; p=000b7003) 6 months later.",
       "nnt":"No effect size.",
       "cohen":"Effect size immediately ~ 0.26, and 6m later ~ 0.27 ",
       "citation":"Zurovac, D., Sudoi, R. K., Akhwale, W. S., Ndiritu, M., Hamer, D. H., Rowe, A. K., & Snow, R. W. (2011). The effect of mobile phone text-message reminders on Kenyan health workers' adherence to malaria treatment guidelines: a cluster randomised trial. Lancet (London, England), 378(9793), 7952013803. https: doi.org 10.1016 S0140-6736(11)60783-6",
       "esdef":"Tthe intention-to-treat analysis nudge shows a small effect size ( d ~ 0.2) but a sustained improvement 6 months later."
    },
    {
       "nudgetype":"Priming",
       "biases":"Feedback, Facilitation, Authority",
       "behavior":"Poor adherence to guidelines ",
       "target":"Increase adherence to guidelines to reduce lumbar spine MRI use for low back pain; specifically, likelihood of a finalized lumbar spine MRIfor lower back pain the day of a visit or 1-30 days after",
       "tasks":"Four types of guidelines adherence feedback were tested on eight tertiary health care system outpatient PCP practices: no feedback during baseline (March 1, 2012 - October 4, 2012), randomization by practice to either clinical decision support (CDS)-generated report cards comparing providers to peers only or real-time CDS alerts at order entry during intervention 1, and both feedback types for all practices during intervention 2",
       "Impact":"proportion of LBP visits increased over the course of the study; in multilevel hierarchic regression, report cards resulted in lower likelihood of LS MRI orders made the day of and 1-30 days after the visit versus baseline: 38% and 37% for report cards alone, and 27% and 27% with alerts, respectively; real-time alerts alone did not affect MRI orders mde the day of or 1-30 days after the visit; no patient or provider variables were associated with LS MRI orders being generated on the day of or 1-30 days after the LBP visit",
       "nnt":"Effect size ~ 10",
       "cohen":"No effect size. ",
       "citation":"Zafart, H. M., Ip, I. K., Mills, A. M., Raja, A. S., Langlotz, C. P., & Khorasani, R. (2019). Effect of Clinical Decision Support-Generated Report Cards Versus Real-Time Alerts on Primary Care Provider Guideline Adherence for Low Back Pain Outpatient Lumbar Spine MRI Orders. American Journal of Roentgenology, 212(2), 3862013394.",
       "esdef":"Nudge needs to be used on 10 physicians in order for 1 physician to increase adherence to guidelines that reduce lumbar spine MRI use."
    },
    {
       "nudgetype":"Salience, Priming",
       "biases":"Facilitation, Anchoring, Authority, Availability",
       "behavior":"Poor adherence to hand rubbing and high incidence of serious infections in LTCFs with elderly residents",
       "target":"Increase adherence to hand rubbing and reduce the incidence of serious infections in LTCFs with elderly residents",
       "tasks":"After a 3-month preintervention period, LTCFs for elderly persons were randomized to receive pocket-sized containers of alcohol-based gel, reminder materials, and education for all HCWs (treatment group) or to receive basic life support education and workshops for all healthcare workers (HCWs)(control group). A 2-week intervention period was followed by 7 months of postintervention observations",
       "Impact":"Total hand hygiene adherence increased from 86 of 333 (25.8%) to 488 of 1465 (33.3%) opportunities after intervention; in treatment group, the incidence of serious infections decreased from 31 cases in 21,862 resident-days to 33 cases in 50,441 resident-days; in treatment group, incidence of pneuomonia decreased from 0.91 to 0.28 cases per 1,000 resident days and the death rate due to infection decreased from 0.37 to 0.10 deaths per 1000 resident days",
       "nnt":"Effect size ~ 7",
       "cohen":"Effect Size ~ 0.01",
       "citation":"Yeung, W. K., Tam, W. S. W., & Wong, T. W. (2011). Clustered randomized controlled trial of a hand hygiene intervention involving pocket-sized containers of alcohol-based hand rub for the control of infections in long-term care facilities. Infection Control and Hospital Epidemiology, 32(1), 67201376.",
       "esdef":"Nudge needs to be used on 7 healthcare workers in order for 1 to improve hand hygeine.\n\nThe nudge has a very small effect size (d < 0.2). "
    },
    {
       "nudgetype":"Priming",
       "biases":"Authority, Anchoring, Facilitation",
       "behavior":"Poor adherence to post-polypectomy surveillance guidelines - exposng patients to excess risk of complications and costs without expected benefit, and others to missed opportunities for prevention",
       "target":"Increase adherence to post-polypectomy surveillance guidelines",
       "tasks":"Intervention consisted of a standardized, electronic health record-embedded worksheet, which requires the physician interpreting pathology findings to identify the most significant colonoscopy findings; ",
       "Impact":"The rate of guideline adherence was significantly better in the post- (97.3%; n=73 75) versus the pre-intervention period (88%; n=66 75, p=0.03). ",
       "nnt":"Effect size ~ 11",
       "cohen":"Effect Size ~ 0.68",
       "citation":"Veerappan A. & Gupta S. (2016). A standardized pathology reporting workflow improves frequency of guideline concordant post polypectomy surveillance recommendations. American Journal of Gastroenterology, 111((Veerappan A.) University of California San Diego, San Diego, CA, United States), S137.",
       "esdef":"Nudge needs to be used on 11 physicians in order for 1 to increase adherence to post-polypectomy surveillance guidelines.\n\nThe nudge has a moderate to large effect size (0.5 < d < 0.8)  suggesting a substantial increase in guideline adherence at moderate sample sizes. "
    },
    {
       "nudgetype":"Priming",
       "biases":"Feedback, Social Norms, Anchoring",
       "behavior":"Poor adherence to emergency physician guidelines",
       "target":"improve adherence to emergency physician guidelines for appropriate antibiotics adminstration for inpatient pneuomnia and completion of the 3-hour Surviving Sepsis Bundle for severe sepsis",
       "tasks":"Attending physicians were randomized into 6 clusters; once a cluster crossed into intervention group - physicians began receiving detailed feedback with blinded peer comparison on their adherence to guidelines for pneumonia and sepsis",
       "Impact":"Feedback with blinded peer comparison significantly improved guideline adherence from 52% without feedback to 65% with feedback - significantly improved emergency physician guideline adherence (difference = 13%, 95% confidence interval = 4% to 22%). ",
       "nnt":"Effect size ~ 8",
       "cohen":"Effect Size ~ 0.19",
       "citation":"Trent, S. A., Havranek, E. P., Ginde, A. A., & Haukoos, J. S. (2019). Effect of Audit and Feedback on Physician Adherence to Clinical Practice Guidelines for Pneumonia and Sepsis. American Journal of Medical Quality, 34(3), 2172013225.",
       "esdef":"Nudge needs to be used on 8 physicians in order for 1 to improve adherence to emergency guidelines for antibiotic administration.\n\nThere is a small effect size (d ~ 0.2). "
    },
    {
       "nudgetype":"Priming, Salience",
       "biases":"Anchoring, Facilitation",
       "behavior":"Increased prescription of aggressive treatment options for patients",
       "target":"Decrease prescription of aggressive treatment options & improve patient care (w o constraining physician's ability to prescrie what they believe is best for their patients)",
       "tasks":"questionnaire consisted on 7 clinical vignettes; each vignette described typical signs and symptoms for acute respiratory infections, and providers chose treatments from a menu of options; for each vignette, providers were randomly assigned to one of two menu partitions - for antibiotic-inappropraite vignettes, the treatment menu either listed over-the-counter medications individually while grouping prescriptions together, or displayed the reverse - for the antibiotic-appropriate vignettes, treatment menu either listed narrow-spectrum antibiotics individually while grouping broad-spectrum antibiotics, or displayed the reverse partition",
       "Impact":"Across vignettes, there was an 11.5 percentage point reduction in choosing aggressive treatment options (broad-spectrum antibiotics)when aggressive options were grouped compared to when the same options were listed individually (95 % CI, 4.4 to 19.5 %; p < 0.01).",
       "nnt":"Effect size ~ 9",
       "cohen":"Effect Size ~ 0.34",
       "citation":"Tannenbaum, D., Doctor, J. N., Persell, S. D., Friedberg, M. W., Meeker, D., Friesema, E. M., Goldstein, N. J., Linder, J. A., & Fox, C. R. (2015). Nudging Physician Prescription Decisions by Partitioning the Order Set: Results of a Vignette-Based Study. Journal of General Internal Medicine, 30(3), 2982013304.",
       "esdef":"Nudge needs to be used on 9 physicians in order for 1 physician to decrease the prescription of aggressive treatment options and improve patient care.\n\nThe nudge produces a small to moderate effect size (0.2 < d < 0.5). "
    },
    {
       "nudgetype":"Priming",
       "biases":"Anchoring, Facilitation, Availability, Feedback",
       "behavior":"Increased concomitant prescribing of NSAIDS and warfarin ",
       "target":"Prevent concurrent orders of warfarin and non-steroidal anti-inflammatory drugs (NSAIDs) - through incremental effectiveness of an alert that required a response from the provider",
       "tasks":"Randomized clinical trial of 1963 clinicians assigned to either an intervention group receiving a customized electronic alert requiring affirmative response or a control group receiving a commericially available passive alert as part of the CPOE - study duration was 2 August 2006 to 15 Decemebr 2007",
       "Impact":"Proportion of desired ordering responses was lower in the intervention group than in the control group - 25% vs 28%; customized CPOE alert that required a provider response had no effect in reducing concomitant prescriving of NSAIDs and wargarin beyond that of the commerically available passive alert received by the control group",
       "nnt":"Effect size ~ 33",
       "cohen":"No effect size. ",
       "citation":"Strom, B. L., Schinnar, R., Bilker, W., Hennessy, S., Leonard, C. E., & Pifer, E. (2010). Randomized clinical trial of a customized electronic alert requiring an affirmative response compared to a control group receiving a commercial passive CPOE alert: NSAID2014warfarin co-prescribing as a test case. Journal of the American Medical Informatics Association, 17(4), 4112013415.",
       "esdef":"Nudge needs to be used on 33 clinicians in order for 1 clinician to reduce concurrent orders of warfarin and NSAIDs.No significant effect."
    },
    {
       "nudgetype":"Priming",
       "biases":"Anchoring, Availability",
       "behavior":"Inconsistent clinican ordering behavior",
       "target":"Attain consistent clincian ordering behavior - # of tests ordered per patient-day & tests performed per patient-day and Medicare associated fees",
       "tasks":"Inpatient laboratory test groups were randomly assigned to display Medicare allowable fees (30 in intervention) or not (30 in control) in the electronic health record - primary outcome was the # of tests ordered per patient-day - secondary outcome were tests performed per patient-day and Medicare associated fees",
       "Impact":"In adjusted analyses of the intervention group compared with the control group over time, there were no significant changes in overall test ordering behavior (0.05 tests ordered per patient-day) or associated fees - displaying medicare allowable fees for inpatient laboratory tests did not lead to a significant change in overall clinician ordering behavior or associated fee",
       "nnt":"No effect size",
       "cohen":"No effect size",
       "citation":"Sedrak, M. S., Myers, J. S., Small, D. S., Nachamkin, I., Ziemba, J. B., Murray, D., Kurtzman, G. W., Zhu, J., Wang, W., Mincarelli, D., Danoski, D., Wells, B. P., Berns, J. S., Brennan, P. J., Hanson, C. W., Dine, C. J., & Patel, M. S. (2017). Effect of a Price Transparency Intervention in the Electronic Health Record on Clinician Ordering of Inpatient Laboratory Tests: The PRICE Randomized Clinical Trial. JAMA Internal Medicine, 177(7), 9392013945.",
       "esdef":"Nudge was not proven to change clinician ordering behavior or Medicare associated fees. "
    },
    {
       "nudgetype":"Priming",
       "biases":"Anchoring, Availability, Facilitation",
       "behavior":"Inconsistent compliance with SCIP indicators",
       "target":"Increase compliance with SCIP indicators - antibiotic adminstration within 60 minutes of surgical incision as documentation - indicators encourage implementation of evidence-based guidelines consistently into clinical practice",
       "tasks":"SCIP 1 (Surgical Care Improvement Project core measures) compliance and the corresponding outcome variable (SSI) were examined prospectively over 2 consecutive 6-month periods before (A) and after (B) POCEPs (point-of-care electronic prompts) implementation at a regional health system - secondary analysis extended the observation to two 12-month periods (A' & B')",
       "Impact":"POCEPs increased compliance with SCIP indicators in period B by 31% from 62% to 92% an were associated with a sustainable, contemporaneous decrease in the incience of SSI from 1.1% to 0.7%; POCEPs may be useful to modulate provider behavior and demonstrate intraoperative quality and value",
       "nnt":"Effect size ~ 3",
       "cohen":"Effect Size ~ 0.58",
       "citation":"Schwann, N. M., Bretz, K. A., Eid, S., Burger, T., Fry, D., Ackler, F., Evans, P., Romancheck, D., Beck, M., & Ardire, A. J. (2011). Point-of-care electronic prompts: An effective means of increasing compliance, demonstrating quality, and improving outcome. Anesthesia & Analgesia, 113(4), 8692013876.",
       "esdef":"Nudge needed to be used on 3 providers in order for 1 provider to increase compliance with SCIP indicators.\n\nThis nudge produces a moderate effect size (d ~ 0.5). "
    },
    {
       "nudgetype":"Priming",
       "biases":"Anchoring, Availability, Facilitation, Social Norms",
       "behavior":"Poor adherence to the recommendations of the national guideline for management of community-acquired pneumonia (CAP) and the routine care",
       "target":"Improve the quality of care of patients with CAP & increase adherence to guidelines to care for patients with community acquired pneuomonia (CAP)",
       "tasks":"Participants in the intervention group received an educational lesson, a poster; a short printed version and an electronic version of the guidelines; subjects also received reminders that included a comparison of the treatment before and after guidelines implementation every second month; subjects in the control group were only informed about the study",
       "Impact":"Active guideline implementation yielded an increaed proportion of guidelines adherence to the length of antibiotic treatment in outpatients (+9.2%), the recommended antibiotics treatment (+5.6%) and duration of antibiotic treatment in inpatients (+5.0%) compared with baseline; in contrast, the proportion of patients in the control group, treated according to the guideline, decreased in the same period by 7.9%, 2.9%, and 4.7%, respetively. Decrease of CAP-related mortality was higher in the intervention group compared with the control group (2.9% vs 0.5%, ns)",
       "nnt":"Effect size ~ 42",
       "cohen":"No effect size. ",
       "citation":"Schnoor, M., Meyer, T., Suttorp, N., Raspe, H., Welte, T., Sch00e4fer, T., & Group, C. S. (2010). Development and evaluation of an implementation strategy for the German guideline on community-acquired pneumonia. BMJ Quality & Safety, 19(6), 4982013502.",
       "esdef":"Nudge needs to be used on 42 physicians in order for 1 to improve the quality of care for CAP patients."
    },
    {
       "nudgetype":"Priming, Salience, Messenger",
       "biases":"Social Norms, Authority, Anchoring, Availability, Facilitation",
       "behavior":"Poor uptake of seasonal influenza vaccination by front-line hospital staff",
       "target":"Increase uptake of seasonal influenza vaccination by front-line hospital staff",
       "tasks":"The standard letter included only general information directing the staff to take up the vaccine. A second letter highlighted a type of social norm based on peer comparisons. A third letter highlighted a type of social norm based on an appeal to authority. A fourth letter included a combination of the social norms. Objective was to evaluate the effectiveness of reminder letters informed by social normative theory on uptake of seasonal influenza vaccination by front-line hospital staff",
       "Impact":"Vaccine coverage waas 43% (812 1885) in the standard letter group, 43% (818 1885) in the descriptive norms group, 43% (814 1885) in the injunctive norms group an 43% (812 1885) in the combination group. There were no statistically significant effects of either norm or the interaction.",
       "nnt":"No effect size",
       "cohen":"No effect size",
       "citation":"Schmidtke, K. A., Nightingale, P. G., Reeves, K., Gallier, S., Vlaev, I., Watson, S. I., & Lilford, R. J. (2020). Randomised controlled trial of a theory-based intervention to prompt front-line staff to take up the seasonal influenza vaccine. BMJ Quality & Safety, 29(3), 1892013197.",
       "esdef":"Nudge was not proven to increase uptake of seasonal influenza vaccines."
    },
    {
       "nudgetype":"Priming, Norms",
       "biases":"Anchoring, Facilitation, Authority",
       "behavior":"Overprescription of quetiapine fumarate, antipsychotic agent, for indications not supported by clinical evidence, potentially causing harm",
       "target":"Reducing prescribing of quetiapine by high-volume primary care prescribers",
       "tasks":"Prescribers were randomized (1:1) ratio to receive a placebo letter or 3 peer comparison letters stating that their quetiapine prescribing was high relative to their peers and was under review by Medicare.",
       "Impact":"Over 9 months, the treatment arm suplied 11.1% fewer quetiapine days per prescriber vs the control arm, which persisted through 2 years; at the patient level, individuals in the treatment arm received 3.9% fewer days of quetiapine from all prescribers over 9 months, with a larger decrease among patients with low-value vs guidelined-concordant indications - peer comparison letters caused substantial and durable reductions in quetiapine prescribing, with no evidence of negative effects on patients",
       "nnt":"Effect size ~ 26",
       "cohen":"Effect Size ~ 0.22",
       "citation":"Sacarny, A., Barnett, M. L., Le, J., Tetkoski, F., Yokum, D., & Agrawal, S. (2018). Effect of Peer Comparison Letters for High-Volume Primary Care Prescribers of Quetiapine in Older and Disabled Adults: A Randomized Clinical Trial.JAMA psychiatry,75(10), 100320131011. https: doi.org 10.1001 jamapsychiatry.2018.1867",
       "esdef":"Nudge needs to be used on 26 prescribers in order for 1 prescriber to reduce quetiapine prescriptions.\n\nThis nudge has a small effect size (d ~ 0.2). "
    },
    {
       "nudgetype":"Priming, ",
       "biases":"Storytelling, Feedback, Facilitation, Anchoring, Availability",
       "behavior":"poor compliance rate of healthcare workers with recommended hand hygiene procedures ",
       "target":"improve healthcare workers' compliance with hand hygiene in eleven intensive care units (ICU)",
       "tasks":"A multimodal intervention was designed based on practices characterized by being evidence-based, low cost and suggested by qualitative research: (i) leadership commitment, (ii) surveillance of materials needed to comply with hand hygiene and alcohol consumption (iii) utilization of reminders (iv) a storyboard of the project and (v) feedback (hand hygiene compliance rate)",
       "Impact":"Compliance with hand hygiene in the control group was 66.0% (2354 3565) vs. 75.6% (5190 6864) in the intervention group. - univariate analysis showed an association between the intervention & hand hygiene compliance",
       "nnt":"Effect size ~ 10",
       "cohen":"Effect Size ~ 0.32",
       "citation":"Rodriguez, V., Giuffre, C., Villa, S., Almada, G., Prasopa-Plaizier, N., Gogna, M., Gibbons, L., Garc00eda Elorrio, E., & Argentinian Group Hand Hygiene Improvement (2015). A multimodal intervention to improve hand hygiene in ICUs in Buenos Aires, Argentina: a stepped wedge trial.International journal for quality in health care : journal of the International Society for Quality in Health Care,27(5), 4052013411. https: doi.org 10.1093 intqhc mzv065",
       "esdef":"Nudge needs to be used on 10 healthcare workers in order for 1 healthcare worker to improve compliance with hand hygeine.\n\nThe nudge produces a small to moderate effect size (0.2 < d < 0.5). "
    },
    {
       "nudgetype":"Priming",
       "biases":"Feedback",
       "behavior":"Poor prescribing quality",
       "target":"Improve adherence to guidelines on prescriptions - through consultant performance audit and feedback on antimicrobial prescribing behavior",
       "tasks":"Prescribing performance of medical consultant teams rotating on the acute medical admissions unit was measured against four quality indicators; measurements were taken at baseline then at quarterly intervals during which time consultants received feedback; proportion of prescriptions adhering to each indicator was compared with baseline using paired sample z-test ",
       "Impact":"2609 antimicrobial prescriptions were reviewed - antimicrobials should have a documented indication in the medical notes' and 'antimicrobials should adhere to guideline choice or have a justification for deviation - reaching 6.0% and 8.7% respectively - Adherence to the indicator 'antimicrobials should have a documented stop review prompt' improved significantly in all but the first follow-up period. For the indicator: 'antimicrobial assessed by antimicrobial specialists as unnecessary', improvement was statistically significant in the first ",
       "nnt":"Effect size ~ 37",
       "cohen":"Effect Size ~ 0.07",
       "citation":"Roberts, E., Dawoud, D. M., Hughes, D. A., & Cefai, C. (2015). Evaluation of a consultant audit and feedback programme to improve the quality of antimicrobial prescribing in acute medical admissions. International Journal of Pharmacy Practice, 23(5), 3332013339.",
       "esdef":"Nudge needs to be used on 37 prescribers in order for 1 to improve adherence to prescription guidelines.\n\nThe nudge produces a very small effect size (d < 0.2). "
    },
    {
       "nudgetype":"Priming, Salience",
       "biases":"Anchoring, Availability, Facilitation ",
       "behavior":"Abusive prescription of benzodiazepines (antianxiety medications) an quetiapine (an antipsychotic medication) - subject to abuse in prison",
       "target":"Reduce prescription of benzodiazepines & quetiapine",
       "tasks":"The University of Medicine and Dentistry of New Jersey-University Correctional HealthCare (UCHC), working within the New Jersey Department of Corrections, provided its psychiatrists with a guideline to the treatment of insomnia in prison - guideline discouraged pharmacological treatment of insomnia - UCHC then anonymously compared the prescribing practices of its psychiatrists to each other, and educated the psychiatrists about the disadvantages of benzodiazepines and low-dose quetiapine in prison - guideline, education, and peer comparison",
       "Impact":"techniques reduced the numbers of inmates prescribed benzodiazepines by 38% after 20 months and reduced the numbers of inmates prescribed low-dose quetiapine by 59% after 22 months",
       "nnt":"Effect size ~ 2",
       "cohen":"No Effect Size",
       "citation":"Reeves R. (2012). Guideline, education, and peer comparison to reduce prescriptions of benzodiazepines and low-dose quetiapine in prison.Journal of correctional health care : the official journal of the National Commission on Correctional Health Care,18(1), 45201352. https: doi.org 10.1177 1078345811421591",
       "esdef":"Nudge needs to be used on 2 prescribers in order for 1 prescriber to reduce benzodiazepine and quetiapine prescriptions."
    },
    {
       "nudgetype":"Priming, Affect",
       "biases":"Availability, Anchoring, Facilitation, Affect, Fear, Framing",
       "behavior":"Hand hygiene compliance (HHC) rates remain dismally low",
       "target":"Increase adherence to hand hygiene compliance (HHC) for governing healthcare workers (HCWs)",
       "tasks":"each participant performed a focused physical examination on a standardized patient and was expected to maintain HH before and after the examination; in control group, the hand sanitizer dispenser was in its usual location; in one group, the dispenser was relocated to direct line of sight (Line-of-sight) on entering the room; in another, flashing lights were affixed to the dispenser in its usual location (baseline & flicker); and in a third group, the dispenser was relocated to the line of sight, and flashing lights were attached; in last group, a large warning sign (Warning sign) was affixed to the door, informing the healthcare provider that the room was under electronic surveillance, and failure to perform HHC would trigger an alarm",
       "Impact":"pre and post-examination HHC rates were 36.7% and 33.3% respectively; all intervention improved HHC preintervention compared with baseline (Line-of-sight = 53.3%, baseline & flicker = 60%, line-of-sight & flicker = 66%, warning sign = 93.3%) but Line-of-sight and flicker & the warning sign produced statistically significant increased pre-examination HHC. Only the warning sign produced statistically significant increased HHC postexamination",
       "nnt":"Effect size ~ 29",
       "cohen":"No Effect Size",
       "citation":"Nevo, I., Fitzpatrick, M., Thomas, R. E., Gluck, P. A., Lenchus, J. D., Arheart, K. L., & Birnbach, D. J. (2010). The efficacy of visual cues to improve hand hygiene compliance.Simulation in healthcare : journal of the Society for Simulation in Healthcare,5(6), 3252013331. https: doi.org 10.1097 SIH.0b013e3181f69482",
       "esdef":"Nudge needs to be used on 29 healthcare worker  in order for 1 healthcare worker to increase hand hygiene compliance."
    },
    {
       "nudgetype":"Priming, Messenger",
       "biases":"Feedback, Anchoring, Availability, Facilitation",
       "behavior":"Overprescription of parenteral steroids (PSs) by general practicioners",
       "target":"Reduce prescribing of parenteral steroids (PSs) by general practicioners",
       "tasks":"Intervention was the provision of 3 feedback messages containing prescribing indices in traditional paper letters (TPL) and short text messages (STM) versus the control arm with an interval of 3 months between these messages; calculated the PS Defined Daily Dose (DDD) for every GP, every month, and compared between the 3 arms, before and after the interventions",
       "Impact":"prescribed parenteral steroid DDDs at baseline were similar (TPL = 121.62, STM = 127.49, CG = 115.68, P > 0.5). At the end of the study DDSs in the TPL and STM arms were similar (TPL = 104.38, STM = 101.90, P > 0.9) but DDDs in each intervention arm was statistically significantly lower than in CG; being in TPL and STM arms resulted in 36.1 and 41.7 units of decrease in DDD respectively, compared to the control arm ( P < 0.02 and P < 0.005) after the one-year duration of study",
       "nnt":"Effect size ~ 14",
       "cohen":"No Effect Size",
       "citation":"Nejad, A. S., Noori, M. R., Haghdoost, A. A., Bahaadinbeigy, K., Abu-Hanna, A., & Eslami, S. (2016). The effect of registry-based performance feedback via short text messages and traditional postal letters on prescribing parenteral steroids by general practitioners--A randomized controlled trial. International journal of medical informatics, 87, 36201343. https: doi.org 10.1016 j.ijmedinf.2015.12.008",
       "esdef":"Nudge needs to be used on 14 physicians in order for 1 physician to reduce parenteral steroid prescriptions."
    },
    {
       "nudgetype":"Priming",
       "biases":"Anchoring, Availability",
       "behavior":"high escalation and continuation of anti-methicillin-resistant Staphylococcus aureus or antipseudomonal therapy",
       "target":"Improve antibiotic prescribing for pneumonia; discontinue prescribing of unecessary broad-spectrum antibiotics, specifically anti-methicillin-resistant Staphylococcus auereus or antipseudomonal therapy",
       "tasks":"intervention involved a 1-page written educational handout used in conjunction with the reporting change; change in microbiology reporting was designed to highlight the absence of MRSA and P. aeruginosa; respiratory cultures with no dominant organism growth and no Pseudomonas sp. or Staphylococcus auereus were reported by the clinical microbiology laboratory as \"commensal respiratory flora only: No S. aureus MRSA or P.[Pseudomonas] aeruginosa; before intervention, these were reported as \"commensal respiratory flora\"",
       "Impact":"De-escalation discontinuation was more commonly performed in the intervention group (39% vs 73%, P < 0.001). After adjusting for APACHE II and Charlson Comorbidity Index, the intervention comment was associated with a 5.5-fold increased odds of de-escalation. Acute kidney injury was reduced in the intervention phase. No difference in all-cause mortality was detected between the groups",
       "nnt":"Effect size ~ 3",
       "cohen":"Effect Size ~ 0.51",
       "citation":"Musgrove, M. A., Kenney, R. M., Kendall, R. E., Peters, M., Tibbetts, R., Samuel, L., & Davis, S. L. (2018). Microbiology Comment Nudge Improves Pneumonia Prescribing.Open forum infectious diseases,5(7), ofy162. https: doi.org 10.1093 ofid ofy162",
       "esdef":"Nudge needs to be used on 3 physicians in order for 1 physician to improve antibiotic prescriptions.\n\nThis nudge produces a moderate effect size (d ~ 0.5). "
    },
    {
       "nudgetype":"Priming",
       "biases":"Facilitation, Anchoring",
       "behavior":"poor hand hygiene among anesthesiology healthcare workers",
       "target":"increase hand hygiene among anesthesiology staff in operating room",
       "tasks":"Intervention involved placement of hand sanitizer on the anesthesia machine. The study randomized subjects to either the intervention (using a hand sanitizer dispenser on the anesthesia machine in addition to the standard wall-mounted dispensers) or the control (presence of wall mounted hand sanitizer dispensers only)",
       "Impact":"40 anesthesia providers were evaluated with and without hand sanitizer dispensers present on the anesthesia machine; having a dispenser increased the frequency of hand hygiene only from 0.5 to 0.8 events per hour (P = 0.01) - other concomitant interventions are needed to further increase hand hygiene frequency among anesthesia providers",
       "nnt":"Effect size ~ 3",
       "cohen":"No Effect Size",
       "citation":"Munoz-Price, L. S., Patel, Z., Banks, S., Arheart, K., Eber, S., Lubarsky, D. A., & Birnbach, D. J. (2014). Randomized crossover study evaluating the effect of a hand sanitizer dispenser on the frequency of hand hygiene among anesthesiology staff in the operating room.Infection control and hospital epidemiology,35(6), 7172013720. https: doi.org 10.1086 676425",
       "esdef":"Nudge needs to be used on 3 anesthesiologists in order for 1 anesthesiologist to improve hand hygeine."
    },
    {
       "nudgetype":"Priming, Messenger",
       "biases":"Availability, Anchoring, Authority, Facilitation",
       "behavior":"Inappropriate prescribing of antibiotics for acute respiratory infections (ARIs)",
       "target":"Decrease inappropriate antibiotic prescribing for ARIs (acute respiratory infections)",
       "tasks":"Intervention consisted of displaying poster-sized commitment letters in examination rooms for 12 weeks; letters featured clinician photographs and signatures & stated their commitment to avoid inappropriate antibiotic prescribing for ARIs",
       "Impact":"Baseline rates were 43.5% & 42.8% for control and poster, respectively; during intervention period, inappropriate prescribing rates increased to 52.7% for controls but decreased to 33.7% in the posted commitment letter condition - displaying poster-sized commitment letters in examination rooms decreased inappropriate antibiotic prescribing for ARIs",
       "nnt":"Effect size ~ 5",
       "cohen":"No Effect Size",
       "citation":"Meeker, D., Knight, T. K., Friedberg, M. W., Linder, J. A., Goldstein, N. J., Fox, C. R., Rothfeld, A., Diaz, G., & Doctor, J. N. (2014). Nudging guideline-concordant antibiotic prescribing: A randomized clinical trial. JAMA Internal Medicine, 174(3), 4252013431.",
       "esdef":"Nudge needs to be used on 5 clinicians in order for 1 clinician to reduce inappropriate antibiotic prescriptions."
    },
    {
       "nudgetype":"Priming",
       "biases":"Anchoring, Availability, Facilitation",
       "behavior":"Inappropriate antibiotic prescribing during ambulatory visits for acute respiratory tract infections",
       "target":"Decrease rates of inappropriate antibiotic prescribing during ambulatory visits for acute respiratory tract infections",
       "tasks":"3 behavioral interventions - implemented alone or in combination - suggested alternatives presented electronic order sets suggesting nonantibiotic treatments; accountable justification prompted clinicians to enter free-text justifications for prescribing antibiotics into patients' electronic health records; peer comparison sent emails to clinicians that compared their antibiotic prescribing rates with those of \"top performers\" (those with the lowest inappropriate prescribing rates)",
       "Impact":"Mean antibiotic prescribing rates decreased from 24.1% at intervention start to 13.1% at intervention month 18 (absolute difference, 221211.0%) for control practices; from 22.1% to 6.1% (absolute difference, 221216.0%) for suggested alternatives (difference in differences, 22125.0% [95% CI, 22127.8% to 0.1%]; P2009=2009.66 for differences in trajectories).",
       "nnt":"Effect size ~ 20",
       "cohen":"Effect Size ~ 0.02",
       "citation":"Meeker, D., Linder, J. A., Fox, C. R., Friedberg, M. W., Persell, S. D., Goldstein, N. J., Knight, T. K., Hay, J. W., & Doctor, J. N. (2016). Effect of behavioral interventions on inappropriate antibiotic prescribing among primary care practices: A randomized clinical trial. Jama, 315(6), 5622013570.",
       "esdef":"Nudge needs to be used on 9 clinicians in order for 1 to reduce inappropriate antibiotic prescriptions.\n\nThe nudge produces a very small effect size (d < 0.2). "
    },
    {
       "nudgetype":"Priming",
       "biases":"Feedback, Anchoring, Availability, Facilitation",
       "behavior":"Poor adherence to hand hygiene among healthcare workers (HCWs) & increased incidence of MRSA",
       "target":"increase rates of adherence to hand hygiene among HCWs and to assess the effect on the incidence of hospital-acquired methicillin-resisant Staphylococcus aureus (MRSA) colonization",
       "tasks":"15 units were randomly assigned to the intervention arm and 15 units to usual practice; intervention arm - performance feedback, small-group teaching seminars, and posters - hand hygiene was observed during randomly selected 15-minute periods on each unit, and the incidence of MRSA colonization was measured using weekly surveillance specimens ",
       "Impact":"48.2% for hand hygiene in the intervention group resulted in adherence, compared with 42.6% in control group; no reduction in the incidence of hospital-acquired MRSA colonization in the intervention group",
       "nnt":"Effect size ~ 18",
       "cohen":"No Effect Size",
       "citation":"Mertz, D., Dafoe, N., Walter, S. D., Brazil, K., & Loeb, M. (2010). Effect of a multifaceted intervention on adherence to hand hygiene among healthcare workers: A cluster-randomized trial. Infection Control and Hospital Epidemiology, 31(11), 1170.",
       "esdef":"Nudge needs to be used on 18 healthcare workers in order for 1 healthcare worker to increase increase "
    },
    {
       "nudgetype":"Priming",
       "biases":"Framing, Anchoring, Facilitation",
       "behavior":"Low handover rates between doctors on the intensive care team and the ward teams in the Great Western Hospital",
       "target":"Increase handover rates - critical step in patient care, ensuring continuiy of care, patient safety, and enabling efficient multidisciplinary functioning",
       "tasks":"Simple changes were made to discharge paperwork in the form of a prompt and documentation of to whom handover was made, as well as the creation of space in the daily review sheets for patients with outstanding handover to be completed",
       "Impact":"rates of documentation of handover rose from 0% to 100%; quality improvement project serves to demonstrate that carefully targeted, simple changes to practice in identified critical areas can produce dramatic as well as legally and ethically required results in a very short space of time",
       "nnt":"Effect size ~ 1",
       "cohen":"No Effect Size",
       "citation":"Messing, J. (2015). Improving handover from intensive care to ward medical teams with simple changes to paperwork. BMJ Open Quality, 4(1).",
       "esdef":"Nudge needs to be used on 1 healthcare worker  in order for 1 healthcare worker to increase hand hygiene compliance."
    },
    {
       "nudgetype":"Priming",
       "biases":"Facilitation, Anchoring, Availability",
       "behavior":"Inappropriate prescribing of low-value medications",
       "target":"Encourage more cost-conscious prescribing",
       "tasks":"Prescribers in the randomly assigned intervention arm received a computerized alert whenever they ordered a medication among 4 high-cost medication classes; for each class, a lower cost, equally effective, and safe alternative was available ",
       "Impact":"A total of 15 456 prescriptions for high-cost medications were written during the baseline period including 7223 in the intervention arm and 8233 in the control arm. Overall, all high-cost medicationsdecreased by 32% (p < .0001).",
       "nnt":"Effect Size ~ 3",
       "cohen":"Effect Size ~ 0.2",
       "citation":"Monsen, C. B., Liao, J. M., Gaster, B., Flynn, K. J., & Payne, T. H. (2019). The effect of medication cost transparency alerts on prescriber behavior. Journal of the American Medical Informatics Association, 26(10), 9202013927.",
       "esdef":"Nudge needs to be used on 3 prescribers in order for 1 prescriber to be more cost-conscious about prescribing.\n\nThe nudge produces a small effect size (d ~ 0.2) but is effect with high sample sizes. "
    },
    {
       "nudgetype":"Priming, Norms",
       "biases":"Anchoring, Facilitation, Authority",
       "behavior":"Uneccesary antibiotic prescribing - contributes to antimicrobial resistance",
       "target":"Substantially reduce antibiotic prescribing at low cost + national scale",
       "tasks":"GP's in every practice in the intervention group were sent a letter from the CMO - letter stated that, \"the great majority of practices in England prescribe fewer antibiotics per head than yours\" - practices in control group received no communication",
       "Impact":"GP practices who received the letter changed their prescribing rates by -3.69%, representing an estimated 124,952 fewer antibiotic items dispensed - social norm feedback from a high-profile messenger continues to be effective, when repeated - can substantially reduce antibiotic prescribing at low-cost and on a national scale",
       "nnt":"Effect Size ~ 27",
       "cohen":"Effect Size ~ 0.14",
       "citation":"Ratajczak, M., Gold, N., Hailstone, S., & Chadborn, T. (2019). The effectiveness of repeating a social norm feedback intervention to high prescribers of antibiotics in general practice: A national regression discontinuity design. Journal of Antimicrobial Chemotherapy, 74(12), 360320133610.",
       "esdef":"Nudge needs to be used on 27 general practioners in order for 1 general practioner to reduce antibiotic prescribing.\n\nThe nudge produces a small effect size (d ~ 0.2) but is effect with high sample sizes. "
    },
    {
       "nudgetype":"Priming, Salience",
       "biases":"Anchoring, Availability, Facilitation",
       "behavior":"poor adherence to evidence-based guidelines for use of CT for evaluation of pulmonary embolism (PE) ",
       "target":"improve adherence to evidence-based guidelines for use of CT for evaluation of pulmonary embolism (PE) beyond that achieved with clinical decision support (CDS) alone",
       "tasks":"Reports consisted of individual and anonymized group data on guideline adherence (using wells criteria), use of CT for PE (# of CT examinations for PE per 1000 patients), and yield (% of CT examinations for PE with positive findings)",
       "Impact":"Control group - guideline adherence remained unchanged; in intervention group, guideline adherence increased 8.8% after feedback report implementation from 78.3% to 85.2% - implementation of quarterly feedback reporting resulted in modest but significant increase in adherence to evidence-based guidelines for use of CT for evaluation of PE in ED patients, enhancing the impact of CDS",
       "nnt":"Effect Size ~ 10",
       "cohen":"Effect Size ~ 0.23",
       "citation":"Raja, A. S., Ip, I. K., Dunne, R. M., Schuur, J. D., Mills, A. M., & Khorasani, R. (2015). Effects of Performance Feedback Reports on Adherence to Evidence-Based Guidelines in Use of CT for Evaluation of Pulmonary Embolism in the Emergency Department: A Randomized Trial. American Journal of Roentgenology, 205(5), 9362013940.",
       "esdef":"Nudge needs to be used on 10 physicians in order for 1 physician to increase adherence to guidelines for CT usage while evaluating pulmonary embolisms.\n\nThe nudge produces a small effect size (d ~ 0.2)."
    },
    {
       "nudgetype":"Priming",
       "biases":"Facilitation, Anchoring",
       "behavior":"Poor adherence to blood pressure medications for persons with diabetes; poor provider attention",
       "target":"Increase effective treatment of high blood pressure for patients with diabetes when they need it.",
       "tasks":"Designed a BPA - fired alerts within an electronic health record system during outpatient encounters for patients with diabetes when they had elevated blood pressures and were not on angiotensin receptor blocking medications",
       "Impact":"providers responded to alerts more often after the \"chart closure\" hard stop was implemented - providers ordered the indicated medication more often (41% vs 75%) ",
       "nnt":"Effect Size ~ 3",
       "cohen":"Effect Size ~ 0.75",
       "citation":"Ramirez, M., Maranon, R., Fu, J., Chon, J. S., Chen, K., Mangione, C. M., Moreno, G., & Bell, D. S. (2018). Primary care provider adherence to an alert for intensification of diabetes blood pressure medications before and after the addition of a 201cchart closure201d hard stop. Journal of the American Medical Informatics Association, 25(9), 116720131174.",
       "esdef":"Nudge needs to be used on 3 healthcare workers in order for 1 to to respond to alerts and order more medication.\n\nThe nudge produces a large effect size (d ~ 0.8)."
    },
    {
       "nudgetype":"Priming, Messenger",
       "biases":"Authority, Anchoring, Facilitation, Availability, Affect, Feedback, Framing",
       "behavior":"increased rates of sepsis",
       "target":"Reduce patient mortality at minimal financial cost",
       "tasks":"Efforts made at Blackpool Victoria Hospital to implement the well-established international Surviving Sepsis Guidelines - included posters, pocket guides and stickers inserted in patient notes - all doctors and nurses in acute areas received specific information and education - sepsis teams comprising both doctors and nurses were formed to encourage implementation and to audit and disseminate data",
       "Impact":"Cases were considered prospectively at the time of initial assessment and 198 patients were identified - 85% had blood cultures taken; 74% had lactate levels measured; and 74% received antibiotics within the target time - results demonstrate relatively effective implementation of guidelines in the challenging environment of a district general hospital",
       "nnt":"Effect Size ~ 4",
       "cohen":"No Effect Size",
       "citation":"Page, I., Hardy, G., Fairfield, J., Orr, D., & Nichani, R. (2011). Implementing Surviving Sepsis guidelines in a district general hospital. Journal of the Royal College of Physicians of Edinburgh, 41(4), 3092013315.",
       "esdef":"Nudge needs to be used on 4 healthcare workers in order for 1 to reduce patient mortality rates."
    },
    {
       "nudgetype":"Priming, Salience",
       "biases":"Feedback, Framing, Anchoring, Availability, Facilitation",
       "behavior":"Increased OR Spending",
       "target":"Achieve discretionary OR spending (disposable supplies)",
       "tasks":"Single-institution, prospective study across 3 departments: urology, thoracic, and endocrine - two self-selected procedures per department were subjected to intraoperative supply cost (ISC) feedback via a custom dashboard and monthly email reports-choice overload, social ranking, and threshold effects were leveraged during the study design",
       "Impact":"on average, during study period, there was a significant monthly decrease in costs of approximately 0.5%; post-intervention, there was a nonsignificant additional decrease of 0.6% in monthly cost; overall cost significantly decreased by 20% due to the intervention - deployment of a cost feedback tool using behavioral economic principles resulted in a significant decrease in OR spending without negatively affecting complication rate",
       "nnt":"Effect size ~ 5",
       "cohen":"No Effect Size",
       "citation":"Offodile, A. C., 2nd, Sen, A. P., Holtsmith, S., Escalante, J., Park, A., Terrell, J., Bassett, R., & Perrier, N. D. (2020). Harnessing Behavioral Economics Principles to Promote Better Surgeon Accountability for Operating Room Cost: A Prospective Study.Journal of the American College of Surgeons,230(4), 5852013593. https: doi.org 10.1016 j.jamcollsurg.2019.12.013",
       "esdef":"Nudge needs to be used on 5  people in order for 1 to increase discretionary spending in the OR."
    },
    {
       "nudgetype":"Priming",
       "biases":"Anchoring, Framing, Facilitation, Feedback",
       "behavior":"poor adherence to established blood transfusion guidelines",
       "target":"Increase adherence to established transfusion guidelines - educational lecture, individualized feedback, and group characteristic audit",
       "tasks":"Multimodal Intervention: (1) an hour-long educational lecture reviewing the history of blood transfusion, principles of oxygen delivery, and harms of red blood cell transfusion; (2) emails were sent to clinicians for each blood transfusion performed on stable, low-risk patients, to which clinicians were encouraged (but not required) to respond and discuss the indication for the transfusion; (3) monthly reports summarizing the group transfusion activity over the previous month compared to the baseline period were sent to the participants ",
       "Impact":" Total 6-month transfusions decreased from 284 U to 181 U, a 36% decrease. There were no significant differences in median SICU or hospital lengths of stay. Although SICU discharge Hgb and hospital discharge Hgb were significantly lower in the intervention period (8.4 vs. 8.6 [p = 0.037] and 8.6 vs. 9.0 [p = 0.003]), 30-day readmission and mortality rates were similar. Effects were not durable post-intervention period",
       "nnt":"Effect Size ~ 3",
       "cohen":"No Effect size",
       "citation":"Yeh, D. D., Naraghi, L., Larentzakis, A., Nielsen, N., Dzik, W., Bittner, E. A., Chang, Y., Kaafarani, H. M. A., Fagenholz, P., Lee, J., DeMoya, M., King, D. R., & Velmahos, G. (2015). Peer-to-peer physician feedback improves adherence to blood transfusion guidelines in the surgical intensive care unit. Journal of Trauma and Acute Care Surgery, 79(1), 65201370.",
       "esdef":"The nudges needs to be used on 3 people in order for 1 to receive a decrease in transfusion amount in line with guidelines. Intervention provides immediate but not lasting results. "
    },
    {
       "nudgetype":"Priming",
       "biases":"Feedback, Facilitation",
       "behavior":"Suboptimal hand hygiene compliance of health-care workers",
       "target":"Increase hand hygiene compliance ",
       "tasks":"Enhanced performance feedback & patient participation - 3 groups - control, enhance performance feedback, and enhanced performance feedback plus patient participation",
       "Impact":"between baseline period and the intervention period, mean hand hygiene compliance increased from 66% to 73% in the control group and 66% to 77% in enhanced performance feedback + patient participation group. The absolute difference in compliance attributable to interventions was 4 percentage points (120138; p=0.048).",
       "nnt":"Effect Size ~ 9",
       "cohen":"Effect Size ~0.46",
       "citation":"Stewardson, A. J., Sax, H., Gayet-Ageron, A., Touveneau, S., Longtin, Y., Zingg, W., & Pittet, D. (2016). Enhanced performance feedback and patient participation to improve hand hygiene compliance of health-care workers in the setting of established multimodal promotion: A single-centre, cluster randomised controlled trial. The Lancet Infectious Diseases, 16(12), 134520131355.",
       "esdef":"The nudge needs to be used on 9 people in order to see 1 have increased hand hygiene compliance. \n\nThe is a moderate effect size (d ~ 0.5)."
    },
    {
       "nudgetype":"Priming, Messenger",
       "biases":"Anchoring, Facilitation, Availability, Authority",
       "behavior":"poor compliance with hand hygiene practice",
       "target":"improve compliance with hand hygiene practice",
       "tasks":"The multimodal hand hygiene improvement strategy consisted of training of healthcare workers by teaching sessions, implementation of hydroalcoholic solutions, and installation of reminder posters",
       "Impact":"overall baseline compliance level was 8.1%; HCW's of the intervention group increased their hand hygiene compliance level by 21.6%  compared to control group - study demonstrates that hand hygiene compliance in primary healthcare workers can be improved with a multimodal hand hygiene improvement strategy",
       "nnt":"Effect size ~ 5",
       "cohen":"Effect Size ~0.58",
       "citation":"Mart00edn-Madrazo, C., Soto-D00edaz, S., Salinero-Fort, M. A., Medina-Fern00e1ndez, M., de Santa Pau, E. C., G00f3mez-Campelo, P., & Ab00e1nades-Herranz, J. C. (2012). Cluster randomized trial to evaluate the effect of a multimodal hand hygiene improvement strategy in primary care. Infection Control & Hospital Epidemiology, 33(7), 6812013688.",
       "esdef":"Nudge needs to be used on 5 healthcare workers in order for 1 to improve hand hygeine.\n\nThe is a moderate effect size (d ~ 0.5)."
    },
    {
       "nudgetype":"Priming, Affect",
       "biases":"Anchoring, Facilitation, Affect",
       "behavior":"low compliance with amylase measurement recommendations",
       "target":"increase compliance with amylase measurement recommendations with \"practice point\" -   a highlighted bullet point making explicit a recommendation within the body of a clinical guideline - was added to the guidelines for acute pancreatitis",
       "tasks":"Data set from a 6-month period, ending 3 months prior to the addition of the Practice Point, collected for a previous study, was used with the author's permission as a control group; new data set from the 6-month period starting 3 months after the addition of the Practice Point formed the experimental group ",
       "Impact":"compliance rose by 13% after addition of the Practice Point - improvement was seen where patients were referred directly by their GP to the general surgical teams and patients managed by other specialties - clarifying a key point within a clinical guidelines can have a significant positive effect on compliance",
       "nnt":"Effect size ~ 8",
       "cohen":"Effect Size ~0.29",
       "citation":"Maidlow, S. C., Ardagh, M., Callender, R., & Thomas, O. (2019). Addition of explicit guidance to acute pancreatitis guidelines increases compliance with amylase measurement recommendations. New Zealand Medical Journal, 132(1489), 81201388.",
       "esdef":"Nudge needs to be used on 8 people in order for 1 person to increase compliance withamylase measurement recommendations.\n\nThe nudge has a small effect size (d ~ 0.2)."
    },
    {
       "nudgetype":"Priming, Salience",
       "biases":"Feedback, Anchoring, Facilitation, Availability",
       "behavior":"poor hypertension management - blood pressure,cardiovascular outcomes",
       "target":"improvement of hypertension management by structured physician education and feedback system",
       "tasks":"Information Group: physicians received detailed training on hypertension guidelines,feedback on target-level attainment, and a reminder to intensify treatment after each patient visit; control group did not receive any such measures",
       "Impact":"Guideline-oriented BP target was attained by 56.8% in IG vs. 52.5% in the CG; individual BP target was attained by 57.0% in IG and 51.0% in the CG; BP control in IG was achieved 2 months earlier on average; clinical inertia (absence of medication changes) occured significantly less often in the IG group.The center effect was estimated to be 30.5% [95% confidence interval (CI): 28.0201333.1] ",
       "nnt":"Effect Size ~ 17",
       "cohen":"Effect Size ~ 0.58",
       "citation":"Lueders, S., Schrader, J., Schmieder, R. E., Smolka, W., Wegscheider, K., & Bestehorn, K. (2010). Improvement of hypertension management by structured physician education and feedback system: Cluster randomized trial. European Journal of Cardiovascular Prevention & Rehabilitation, 17(3), 2712013279.",
       "esdef":"Nudge needs to be used on 24 physicians in order for 1 to improve the way they manage patient's hypertension. Delivery of systematic information in connection w a feedback system reduces clinical inertia and improves guideline adherence"
    },
    {
       "nudgetype":"Priming, Salience, Affect",
       "biases":"Availability, Anchoring, Facilitation, Affect",
       "behavior":"inappropriate ordering of common laboratory tests ",
       "target":"encourage reduction in ordering of laboratory tests by physicians - through real-time display of cost information in an electronic health record",
       "tasks":"Average medicare reimbursement rate for 27 laboratory tests was displayed within an electronic health record at the time of ordering, including 21 lower cost tests an 6 higher cost tests",
       "Impact":"intervention physicians demonstrated a significant decrease in ordering rates compared to control physicians (19%) for lower cost laboratory tests and (17%) for higher cost laboratory tests; 81% of physicians reported that intervention improved their knowledge of relative costs of laboratory tests - electronic health records can serve as a tool to promote cost transparency and reduce laboratory test use",
       "nnt":"Effect Size ~ 5",
       "cohen":"No Effect Size",
       "citation":"Horn, D. M., Koplan, K. E., Senese, M. D., Orav, E. J., & Sequist, T. D. (2014). The impact of cost displays on primary care physician laboratory test ordering.Journal of general internal medicine,29(5), 7082013714. https: doi.org 10.1007 s11606-013-2672-1",
       "esdef":"Nudge needs to be used on 5 physicians in order for 1 physician to reduce the amount of laboratory tests ordered."
    },
    {
       "nudgetype":"Priming, Salience",
       "biases":"Facilitation, Anchoring, Availability",
       "behavior":"poor identification of at-risk patients",
       "target":"improve identification of at-risk patients",
       "tasks":"e-Nudge identifies 4 groups of patients aged over 50 years on the basis of estimated cardiovascular risk and adequacy of risk factor data in general practice computers; screen messages highlight individuals at raised risk and prompt users to complete risk profiles where necessary",
       "Impact":"intervention led to an increase in the proportion of patients with sufficient data who were identifiably at risk, with a difference of 1.94% compared to the control group; corresponding reduction occurred in the proportion potentially at risk but requiring further data for a risk estimation - automated electronic reminders uing routinely collected primary care data can improve the adequacy of cardiovascular risk factor information during everyday practice and increase the visibility of the at-risk population",
       "nnt":"Effect Size ~ 52",
       "cohen":"Effect Size ~ 0.04",
       "citation":"Holt, T. A., Thorogood, M., Griffiths, F., Munday, S., Friede, T., & Stables, D. (2010). Automated electronic reminders to facilitate primary cardiovascular disease prevention: Randomised controlled trial. The British Journal of General Practice, 60(573), e1372013e143.",
       "esdef":"Nudge needs to be used on 52 people in order for 1 person to improve identifying at-risk patients.\n\nThe nudge is effect size large sample sizes, but produces a very small effect size (d << 0.2)."
    },
    {
       "nudgetype":"Priming, Salience, Affect, Messenger",
       "biases":"Anchoring, Availability, Faclitation, Feedback, Affect",
       "behavior":"increased demand for CRP blood assays",
       "target":"improve hospital clinicians' sensitivity to feedback of assay costs when placing orders - decrease in # of assays demanded",
       "tasks":"Intervention consisted of a message communicating the cost per test and total amount of money the hospital spent on the assay in a given year",
       "Impact":"Significant decrease in the # of assays after the intervention was implemented - with a mean decrease in demand of 382 CRP assays per week - representing a proportionate decrease from baseline of 32% - 1st study that has investigated the implementation of feedback of the cost of an assay to the requesting clinicians - after introduction of intervention, demand for CRP bloo assays decreased",
       "nnt":"Effect size ~ 3",
       "cohen":"Effect Size ~ 0.68",
       "citation":"Fogarty, A. W., Sturrock, N., Premji, K., & Prinsloo, P. (2013). Hospital clinicians2019 responsiveness to assay cost feedback: A prospective blinded controlled intervention study. JAMA Internal Medicine, 173(17), 165420131655.",
       "esdef":"Nudge needs to be used on 3 clinicians in order for 1 clinician to decrease the number of assays ordered.\n\nThis nudge has a moderate to large effect size (0.5 < d < 0.8)."
    },
    {
       "nudgetype":"Priming, Salience, Messenger, Affect",
       "biases":"Feedback, Affect, Anchoring, Availability, Facilitation, Authority",
       "behavior":"poor guideline adherence to OM",
       "target":"increase guideline adherence through CDS (clinical decision support) adoption system",
       "tasks":"Prospective cohort study of EHR-based CDS (clinical decision support) adoption during OM (otitis media) visits, comparing clinicians receiving performance feedback to none; CDS was available to all physicians; use was voluntary",
       "Impact":"Clinicians (n=108 across 16 practices) who received performance feedback reports summarizing CDS use and guideline adherence had a relative increase in CDS use of 9 percentage points compared to others - CDS adoption was associated with increased OM guideline adherence",
       "nnt":"Effect size ~ 11",
       "cohen":"No Effect Size",
       "citation":"Fiks, A. G., Zhang, P., Localio, A. R., Khan, S., Grundmeier, R. W., Karavite, D. J., Bailey, C., Alessandrini, E. A., & Forrest, C. B. (2015). Adoption of electronic medical record-based decision support for otitis media in children. Health Services Research, 50(2), 4892013513.",
       "esdef":"Nudge needs to be used on 11 clinicians in order for 1 clinician to increase guideline adherence through the CDS adoption system."
    },
    {
       "nudgetype":"Priming, Salience, Incentives",
       "biases":"Feedback, Social Norms, Anchoring, Facilitation, Affect",
       "behavior":"poor compliance with prescribing indicators",
       "target":"increase compliance by GPs with indirect and incentivized quality prescribing indicators",
       "tasks":"Multidisciplinary Group: prescribing indicators were developed using a qualitative technique based on consensus; members of the consensus group searched for updated recommendations focused on clinical evidence; prescribing data were obtained from the computerized pharmacy records of reimbursed drugs and clinical data from the electronic clinical databases and hospital admission records",
       "Impact":"GPs shows better compliance with quality prescribing indicators included in pay-for-performance programs compared with not-incentivized indicators based on the relative use of drugs in other pharmacological areas and on the appropriateness of prescribing. Indicators related to financial incentives (indicators 1 to 6): low-quality standards were reached for all indicators; high-quality standards were reached for 3 of the six indicators.",
       "nnt":"Effect Size ~ 3",
       "cohen":"No Effect Size",
       "citation":"Fern00e1ndez Urrusuno, R., Montero Balosa, M. C., P00e9rez P00e9rez, P., & Pascual de la Pisa, B. (2013). Compliance with quality prescribing indicators in terms of their relationship to financial incentives.European journal of clinical pharmacology,69(10), 184520131853. https: doi.org 10.1007 s00228-013-1542-4",
       "esdef":"The nudge needs to be used on 3 GP's in order for 1 to have better compliance with quality prescribing indicators. "
    },
    {
       "nudgetype":"Default, Framing, Salience",
       "biases":"Feedback, Facilitation, Availability",
       "behavior":"aggressive end-of-life care",
       "target":"increase in SICs and reduction in end-of-life systematic therapy",
       "tasks":"High-risk patients were identified using a validated electronic health record machine learning algorithm to predict 6-month mortality. The intervention consisted of (1) weekly emails to clinicians comparing their SIC (serious illness conversations) rates for all patients against peers' rates, (2) weekly lists of high-risk patients, an (3) opt-our text messages to prompt SICs before encounters with high-risk patients. ",
       "Impact":"Tthe intervention was associated with increased SICs for all patients (adjusted odds ratio, 2.09 [95% CI, 1.53-2.87]; P < .001) and decreased end-of-life systemic therapy (7.5% [72 of 957 patients] vs 10.4% [24 of 231 patients]; adjusted odds ratio, 0.25 [95% CI, 0.11-0.57]; P = .001) relative to controls. ",
       "nnt":"Effect Size for SIC ~ 10\nEffect Size for End-of-Life ~ 34",
       "cohen":"Effect Size for SIC ~ 0.04\nEffect Size for End-of-Life ~ 0.01",
       "citation":"Manz, C. R., Zhang, Y., Chen, K., Long, Q., Small, D. S., Evans, C. N., Chivers, C., Regli, S. H., Hanson, C. W., Bekelman, J. E., Braun, J., Rareshide, C. A. L., O'Connor, N., Kumar, P., Schuchter, L. M., Shulman, L. N., Patel, M. S., & Parikh, R. B. (2023). Long-term Effect of Machine Learning-Triggered Behavioral Nudges on Serious Illness Conversations and End-of-Life Outcomes Among Patients With Cancer: A Randomized Clinical Trial.JAMA oncology, e226303. Advance online publication. https: doi.org 10.1001 jamaoncol.2022.6303",
       "esdef":"Nudge needs to be used on 10 physician's in order for 1 to deliver a SIC to a patient and and 34 for 1 to reduce end of life systematic therapy.\n\nThere is a very small effect size for increasing SIC or decreasing end of life therapy for the nudge (d << 0.2). "
    },{
       "nudgetype":"Priming",
       "biases":"Framing, Proximity, Stimulus Response Compatibility",
       "behavior":"Physicians not sitting at the bedside despite the benefits of etiquette-based care",
       "target":"Increase in hospitalist physicians' sitting at the bedside and increase in patient satisfaction",
       "tasks":"Modify chair placement by positioning the chair within 3 feet (0.9 m) of the bedside and facing the bed",
       "Impact":"38 of the 60 physicians in the chair placement group sat during the patient encounter compared with 5 of the 65 physicians in the control group (odds ratio 20.7, 95% confidence interval 7.2 to 59.4; P<0.001). There was a 4.9% improvement in patients2019 satisfaction.",
       "nnt":"Effect Size ~ 1.8",
       "cohen":"No Effect Size",
       "citation":"Iyer, R., Park, D., Kim, J., Newman, C., Young, A., & Sumarsono, A. (2023). Effect of chair placement on physicians' behavior and patients' satisfaction: randomized deception trial.BMJ (Clinical research ed.),383, e076309. https: doi.org 10.1136 bmj-2023-076309",
       "esdef":"Overall, 1.8 chairs needed to be placed for one physician to sit during the patient encounter."
    }
 ]