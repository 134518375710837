
import { configureStore } from '@reduxjs/toolkit';
import { authReducer} from './Common/Slice';


const Store = configureStore({
  reducer: {
    auth: authReducer,
 
   
  },
});

export default Store;
