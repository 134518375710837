import React, { useState, useEffect } from "react";
import FolderIcon from "@mui/icons-material/Folder";
import "react-multi-carousel/lib/styles.css";
import { ReactComponent as HowTo } from "../../Assets/Svgs/howto.svg";
import { ReactComponent as Resources } from "../../Assets/Svgs/Resources.svg";
import { ReactComponent as Badge } from "../../Assets/Svgs/badge.svg";
import Sidebar from "./SideBar";
import { NavLink } from "react-router-dom";

const Home = () => {
  const [open, setOpen] = useState(true);
  const [contentWidth, setContentWidth] = useState("79.5%");

  useEffect(() => {
    setContentWidth(open ? "89.5%" : "96.5%");
  }, [open]);

  const sections = [
    {
      title: "How-to Videos",
      description:
        "Watch quick tutorials to learn how to use our features efficiently.",
      longDescription:
        "Our How-to Videos provide quick, actionable guides to help you make the most of each feature on the platform. From getting started with your first sprint to advanced tips on engaging stakeholders, each video offers step-by-step instructions with visual examples.",
      icon: <HowTo style={{ width: "100px", height: "100px" }} />,
      link: "/tutorial/howto",
      linkText: "Click here to check “How-to Videos”",
    },
    {
      title: "Resources",
      description:
        "Download helpful resources like guides, templates, and checklists.",
      longDescription:
        "The Resources section is your go-to collection of downloadable templates, guides, and checklists designed to support agile planning and sprint management. Find everything you need, from stakeholder mapping and sprint planning templates to behavior-tracking forms, all created to streamline your workflow.",
      icon: <Resources style={{ width: "100px", height: "100px" }} />,
      link: "/tutorial/resources",
      linkText: "Click here to check “Resources”",
    },
    {
      title: "Successful Nudges",
      description:
        "Explore real-world examples of successful nudges that have made an impact.",
      longDescription:
        "In Successful Nudges, discover real-world examples of nudges that have made a positive impact on teams and projects. Each success story details how the nudge was implemented, the behavioral outcomes achieved, and measurable results.",
      icon: <Badge style={{ width: "100px", height: "100px" }} />,
      link: "/tutorial/sucessfulnudges",
      linkText: "Click here to check “Successful Nudges”",
    },
  ];

  return (
    <div className="flex flex-row h-screen font-roboto bg-img text-base">
      <div style={{ width: open ? "22%" : "60px" }}>
        <Sidebar open={open} setOpen={setOpen} />
      </div>

      <div
        className="flex flex-col justify-start items-center"
        style={{ width: contentWidth }}
      >
        <h1 className="w-full h-[70px] bg-primary text-white text-[28px] font-medium flex items-center pl-[100px]">
          Tutorials and Resources
        </h1>
        <div className="w-[80%] flex flex-col justify-between items-center h-[80%] mt-10 space-y-10">
          {sections.map((section, index) => (
            <div
              key={index}
              className="w-full flex flex-col lg:flex-row justify-between lg:items-start lg:space-x-4 space-y-4 lg:space-y-0"
            >
              <div className="w-full lg:w-[60%] flex flex-col space-y-2">
                <h1 className="text-black text-[28px] font-medium">
                  {section.title}
                </h1>
                <h1 className="text-[#49454f] font-normal border-b-[1px] border-gray-400 pb-1">
                  {section.description}
                </h1>
                <h1 className="text-[#49454f] font-normal mt-1">
                  {section.longDescription}
                </h1>
              </div>
              <div className="bg-bcolor w-full lg:w-[40%] h-auto rounded-[28px] flex flex-col justify-center items-center p-4 space-y-2">
                {section.icon}
                <NavLink to={section.link} className="w-full">
                  <div className="w-full h-10 bg-white rounded-full px-6 py-2.5 text-bcolor font-medium flex items-center justify-center space-x-2">
                    <FolderIcon /> <h1>{section.linkText}</h1>
                  </div>
                </NavLink>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;