import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import anplogo from "../Assets/Images/anplogo.png";
import video from "../Assets/Images/video.png";
import logo from "../Assets/Images/logoabout.png";
import ReactPlayer from "react-player";
const Behaviors = () => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const handleVideoToggle = () => {
    setIsVideoOpen(!isVideoOpen);
  };

  return (
    <div className="flex flex-col h-screen font-roboto text-base">
      <div className="min-h-screen flex flex-col relative bg-primary">
        <div className="absolute inset-0 flex items-center justify-center text-white font-bold z-10 bg-opacity-[80%]">
          <div className="flex flex-col space-y-5 h-screen overflow-scroll w-screen scrollbar-hide justify-center items-center">
            {isVideoOpen ? (
              // Display the YouTube video if `isVideoOpen` is true
              <div className="w-full h-full shadow-lg overflow-hidden flex justify-center items-center rounded-t-[10px] relative">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=9BJy36xgH3Y"
                playing
                loop // Enables looping
                controls={false} // Hides the YouTube controls
                width="82%"
                height="82%"
                className="shadow-lg"
              />
              {/* <button
                onClick={handleVideoToggle} // Directly invoking the function
                className="absolute top-10 right-10 bg-bcolor text-white text-black bg-opacity-50 rounded-full p-4 hover:bg-opacity-70"
              >
                X
              </button> */}
            </div>
            ) : (
              // Display the original content if `isVideoOpen` is false
              <>
                <img
                  src={anplogo}
                  alt=""
                  className="w-[386.65px] h-[346.38px]"
                />
                <div className="flex flex-row space-x-0 text-4xl">
                  <h1 className="text-white text-center font-[Forum] text-[64px] font-normal leading-normal tracking-[9.6px]">
                    AgileNudge
                  </h1>
                  <h1 className="text-white text-center font-[Forum] text-[64px] font-normal leading-normal tracking-[9.6px] -mt-3">
                    +
                  </h1>
                </div>
                <div className="flex flex-row w-[440px] justify-between">
                  <NavLink to="/login" state={{ isLogin: true }}>
                    <button className="h-10 bg-[#00adb5] rounded-full border border-white flex justify-center items-center px-6">
                      <span className="text-white font-medium">
                        Already Have an Account
                      </span>
                    </button>
                  </NavLink>

                  <NavLink to="/login" state={{ isLogin: false }}>
                    <button className="h-10 bg-white rounded-full flex justify-center items-center px-6">
                      <span className="text-[#27292a] font-medium">
                        Create an Account
                      </span>
                    </button>
                  </NavLink>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div
        className="fixed bottom-1 right-5 rounded-full p-4 z-20 cursor-pointer"
        onClick={handleVideoToggle}
      >
        <img
          src={video}
          className="w-[80px] h-[80px] shadow rounded-full"
          alt="Take a tour"
        />
        <div className="text-center text-black font-normal leading-tight tracking-tight">
          {isVideoOpen ? "Close Tour" : "Take a Tour"}
        </div>
      </div>

      <div className="flex flex-col justify-start items-center w-screen">
        <div className="w-[90%] flex flex-col justify-between items-center h-[85%] mt-10 ml-5 bg-[#fef7ff] p-5">
          <img
            src={logo}
            alt="About AgileNudge"
            className="w-[30%] h-[45%] bg-primary"
          />
          <div className="w-full h-[40%] overflow-scroll flex flex-col space-y-4 mt-5">
            <h1>
              We are a team of healthcare advocates and developers of the Agile
              Nudge Plus software. Faced with the real world challenge of rising
              patient suffering due to preventable healthcare issues, we see
              firsthand how ingrained behaviors, cognitive biases and outdated
              practices create barriers to positive outcomes. The enemy is
              clear; problem behaviors in healthcare systems, including
              resistance to change, fragmented care, and inefficient
              communication between stakeholders.
            </h1>
            <h1>
              Determined to make a difference, we embarked on a mission to
              create a viable solution, one that wouldn't just address
              surface-level problems but would drive fundamental behavior
              changes. However, the struggle is real. Implementing change in a
              complex adaptive system often comes with resistance from
              practitioners set in their ways, misaligned incentives, and the
              sheer challenge of reaching the right stakeholders at scale.
            </h1>
            <h1>
              The drama unfolded as our team developed Agile Nudge Plus, a
              behavioral change management tool rooted in agile science. Slowly,
              we gained traction as healthcare providers begin to see the
              efficacy of implementing nudges. Through tireless iterations and
              patient advocacy, Agile Nudge has emerged as the resolution,
              transforming clinical practices and reducing human suffering
              through actionable, evidence-based interventions.
            </h1>
            <h1>
              Agile Nudge Plus is proving that with the right tools, processes,
              and strategy, we can reframe healthcare decisions, helping
              professionals shift from inertia to innovation.
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Behaviors;
