import React, { useState, useEffect } from "react";
import Sidebar from "./SideBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { NavLink } from "react-router-dom";

const TutorialHowTo = () => {
  const [open, setOpen] = useState(true);
  const [contentWidth, setContentWidth] = useState("79.5%");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);

  // Define categories
  const categories = [
    {
      name: "Nudge Library",
      subcategories: [
        {
          id: 1,
          name: "Cognitive Bias Database",
          link: "https://indiana-my.sharepoint.com/:x:/g/personal/vvrushab_iu_edu/EXr7GTGTAa5HjmzDafBT_tYBgXgfX6nNoV--UN3c5rHMgg?rtime=dax156qa3Eg",
        },
        {
          id: 2,
          name: "Nudge Library",
          link: "https://indiana-my.sharepoint.com/:x:/g/personal/vvrushab_iu_edu/EShXP90pTKhIgxRhbm1o_tgB3uyLeNUTm6t0ym9xcL96Rg?e=WBd6Fz",
        },
       
      
      ],
    },
    {
      name: "User Tools",
      subcategories: [
        {
          id: 1,
          name: "User Guide",
          link: "https://storage.googleapis.com/anpresources/userguide.pdf",
        },
        {
          id: 2,
          name: "Nudge U Toolkit",
          link: "https://storage.googleapis.com/anpresources/nudge-u-toolkit.pdf",
        },
        {
          id: 3,
          name: "The methodology of the Agile Nudge University",
          link: "https://www.frontiersin.org/journals/health-services/articles/10.3389/frhs.2023.1212787/full",
        },
      ],
    },
  ];

  useEffect(() => {
    setContentWidth(open ? "89.5%" : "96.5%");
  }, [open]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category); // Set to the whole category object
    setSelectedSubcategory(null);  // Reset selected subcategory
  };

  const handleSubcategoryClick = (subcategory) => {
    setSelectedSubcategory(subcategory);
  };

  return (
    <div className="flex flex-row h-screen font-roboto bg-img text-base">
      <div style={{ width: open ? "22%" : "90px" }}>
        <Sidebar open={open} setOpen={setOpen} />
      </div>

      <div className="flex flex-col w-full" style={{ width: contentWidth }}>
        <div className="w-full h-[70px] bg-primary text-white font-medium flex items-center pl-10">
          <NavLink to={"/tutorial"}>
            <button className="bg-bcolor rounded-full px-6 py-2.5 text-white font-medium flex items-center justify-center space-x-1">
              <ArrowBackIcon /> <h1>Back</h1>
            </button>
          </NavLink>
          <h1 className="text-[28px] opacity-50 ml-5">Tutorials and Resources</h1>
          <h1 className="text-[28px] ml-2"> &gt; Resources</h1>
        </div>

        <div className="flex flex-row h-[90%] mt-6">
          <div className="w-[300px] p-4 border-r-[1px] border-gray-300">
            <h2 className="text-[22px] font-medium mb-4">Categories</h2>
            <ul className="space-y-4">
              {categories.map((category) => (
                <li key={category.name}>
                  <div
                    onClick={() => handleCategoryClick(category)}
                    className={`cursor-pointer p-4 text-[16px] rounded-md ${
                      selectedCategory?.name === category.name
                        ? "bg-[#65558f] text-white"
                        : "bg-[#FEF7FF]"
                    }`}
                  >
                    {category.name}
                  </div>
                  {/* Subcategories */}
                  {selectedCategory?.name === category.name && (
                    <ul className="ml-4 mt-2 space-y-2">
                      {category.subcategories.map((subcategory) => (
                        <li
                          key={subcategory.id}
                          onClick={() => handleSubcategoryClick(subcategory)}
                          className={`cursor-pointer p-3 rounded-md ${
                            selectedSubcategory === subcategory
                              ? "bg-[#65558f] text-white"
                              : "bg-[#D0BCFF]"
                          }`}
                        >
                          {subcategory.name}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>

          <div className="flex-1 p-6">
            <h2 className="text-[24px] font-semibold">
              {selectedSubcategory?.name || selectedCategory?.name || "Select a Category"}
            </h2>
            {selectedSubcategory ? (
              <div className="mt-4 w-full h-[77vh]">
                {selectedSubcategory.link.includes("https://indiana-my.sharepoint.com/:x:/g/personal/vvrushab_iu_edu/") ? (
                  <div>
                    <p className="text-lg mb-4">
                      This CSV file cannot be displayed in the browser. Click the link below to view it:
                    </p>
                    <a
                      href={selectedSubcategory.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 underline"
                    >
                      {selectedSubcategory.name}
                    </a>
                  </div>
                ) : (
                  <iframe
                    src={selectedSubcategory.link}
                    title={selectedSubcategory.name}
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    className="rounded-lg shadow-lg border"
                  ></iframe>
                )}
              </div>
            ) : selectedCategory ? (
              <p className="mt-4">Overview of {selectedCategory.name}</p>
            ) : (
              <p className="mt-4">
                Please select a category or subcategory to view more details.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorialHowTo;