// context/NudgeContext.js

import React, { createContext, useContext, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { v4 as uuidv4 } from "uuid";
import {
  GET_PROBLEM,
  UPDATE_ROOT_PROBLEM,
  GET_ALL_USERS,
  CREATE_USER,
} from "../Graphql/User/Query";
import { cloneDeep } from "lodash";
import ToastAlert, { showToast } from "../Components/Common/ToastAlert";
import { useNavigate } from "react-router-dom";

const NudgeContext = createContext();

export const useNudgeContext = () => useContext(NudgeContext);

export const NudgeProvider = ({ children }) => {
  const [data, setData] = useState(null);
  const navigate = useNavigate()
  const [rootProblem, setRootProblem] = useState("");
  const [inviteDetails, setInviteDetails] = useState({
    stakeholder: null,
    behavior: null,
    nudge: null,
    sprint: null,
  });
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [selectedStakeholderIndex, setSelectedStakeholderIndex] =
    useState(null);
  const [isMapVisible, setIsMapVisible] = useState(false);

  const nudgeOptions = [
    "Messenger",
    "Incentives",
    "Norms",
    "Default",
    "Salience",
    "Priming",
    "Affect",
    "Commitment",
    "Ego",
  ];

  const [getProblem, { data: problemData }] = useLazyQuery(GET_PROBLEM, {
    onCompleted: (data) => {
      setData(data.getProblem);
      setRootProblem(data.getProblem.name);
    },
  });
  const [updateRootProblem] = useMutation(UPDATE_ROOT_PROBLEM);
  const [createUser] = useMutation(CREATE_USER);

  const fetchProblemData = (problemId) => {
    getProblem({
      variables: { userId: localStorage.getItem("userId"), problemId },
    });
  };
  const {
    data: usersData,
    loading: userLoading,
    refetch: refetchUsers,
  } = useQuery(GET_ALL_USERS);

  const handleDelete = (type, stakeholderId, behaviorId, nudgeId, sprinterId) => {
    const updatedData = data.children
      .map((stakeholder) => {
        if (stakeholder.id === stakeholderId) {
          if (type === "STAKEHOLDER") {
            setSelectedStakeholderIndex(null);
            return null;
          }
          if (type === "BEHAVIOR") {
            const updatedBehaviors = stakeholder.children.filter(
              (behavior) => behavior.id !== behaviorId
            );
            return { ...stakeholder, children: updatedBehaviors };
          }
          if (type === "NUDGE") {
            const updatedBehaviors = stakeholder.children.map((behavior) => {
              if (behavior.id === behaviorId) {
                const updatedNudges = behavior.children.filter(
                  (nudge) => nudge.id !== nudgeId
                );
                return { ...behavior, children: updatedNudges };
              }
              return behavior;
            });
            return { ...stakeholder, children: updatedBehaviors };
          }
          if (type === "SPRINTER") {
            const updatedBehaviors = stakeholder.children.map((behavior) => {
              if (behavior.id === behaviorId) {
                const updatedNudges = behavior.children.map((nudge) => {
                  if (nudge.id === nudgeId) {
                    const updatedSprints = nudge.children.filter(
                      (sprinter) => sprinter.id !== sprinterId
                    );
                    return { ...nudge, children: updatedSprints };
                  }
                  return nudge;
                });
                return { ...behavior, children: updatedNudges };
              }
              return behavior;
            });
            return { ...stakeholder, children: updatedBehaviors };
          }
        }
        return stakeholder;
      })
      .filter((stakeholder) => stakeholder !== null);

    setData({ ...data, children: updatedData });
    navigate('/nudgebuilder/custombuilder')
  };

  const handleEdit = (
    type,
    stakeholderId,
    behaviorId,
    nudgeId,
    sprinterId,
    name,
    ismanual
  ) => {
    const updatedChildren = data.children.map((stakeholder) => {
      if (stakeholder.id === stakeholderId) {
        if (type === "STAKEHOLDER") {
          return { ...stakeholder, name };
        }
        if (type === "BEHAVIOR") {
          const updatedBehaviors = stakeholder.children.map((behavior) => {
            console.log(behavior)
            if (behavior.id === behaviorId) {
                if(nudgeId === "target")
                return { ...behavior, target : name };
              return { ...behavior, name };
            }
            return behavior;
          });
          return { ...stakeholder, children: updatedBehaviors };
        }
        if (type === "NUDGE") {
          const updatedBehaviors = stakeholder.children.map((behavior) => {
            if (behavior.id === behaviorId) {
              const updatedNudges = behavior.children.map((nudge) => {
                if (nudge.id === nudgeId) {
                  return { ...nudge, name };
                }
                return nudge;
              });
              return { ...behavior, children: updatedNudges };
            }
            return behavior;
          });
          return { ...stakeholder, children: updatedBehaviors };
        }
        if (type === "SPRINTER") {
          const updatedBehaviors = stakeholder.children.map((behavior) => {
            if (behavior.id === behaviorId) {
              const updatedNudges = behavior.children.map((nudge) => {
                if (nudge.id === nudgeId) {
                  const updatedSprints = nudge.children.map((sprinter) => {
                    if (sprinter.id === sprinterId) {
                      return { ...sprinter, name, ismanual };
                    }
                    return sprinter;
                  });
                  return { ...nudge, children: updatedSprints };
                }
                return nudge;
              });
              return { ...behavior, children: updatedNudges };
            }
            return behavior;
          });
          return { ...stakeholder, children: updatedBehaviors };
        }
      }
      return stakeholder;
    });

    const updatedData = { ...data, children: updatedChildren };
    setData(updatedData);
    return updatedData;
  };

  const addNudge = (stakeholderIndex, behaviorIndex, nudgeType) => {
    setData((prevData) => {
      const updatedData = cloneDeep(prevData);
      const behavior =
        updatedData.children[stakeholderIndex].children[behaviorIndex];
      behavior.children.push({
        id: uuidv4(),
        name: nudgeType.trim(),
        children: [{ id: uuidv4(), name: "", tasks: [] }],
      });

      return updatedData;
    });
  };

  const addBehavior = async (stakeholderIndex, bname, btarget) => {
    return new Promise((resolve) => {
      setData((prevData) => {
        const updatedData = cloneDeep(prevData);
        const stakeholder = updatedData.children[stakeholderIndex];
        const behaviorIndex = stakeholder.children.length;
        stakeholder.children.push({
          id: uuidv4(),
          name: bname,
          target: btarget,
          children: [],
        });
        resolve(behaviorIndex);
        return updatedData;
      });
    });
  };

  const handleInviteUser = async (email) => {
    const SignupUsername = email.split("@")[0];
    const { stakeholder, behavior, nudge, sprint } = inviteDetails;

    const temp = handleEdit(
      "SPRINTER",
      stakeholder,
      behavior,
      nudge,
      sprint,
      email,
      false
    );
    const updatedData = {
      id: data.id,
      name: rootProblem,
      children: temp.children,
    };

    try {
      await createUser({
        variables: { email, username: SignupUsername },
      });
      await updateRootProblem({
        variables: {
          userId: localStorage.getItem("userId"),
          problemId: data.id,
          data: updatedData,
        },
      });
      showToast("User invited successfully!", "success");
      refetchUsers();
    } catch (error) {
      console.error("Error inviting user:", error);
      alert(
        "Failed to invite user. User Either exists or the email id is invalid"
      );
    }
  };

  const handleInviteModalClose = () => {
    setInviteModalOpen(false);
    setNewEmail("");
  };
  const handleOpenSearchModal = (selectedStakeholderIndex) => {
    navigate("/searchlibrary", { state: { stakeholder: selectedStakeholderIndex } });
  };

  return (
    <NudgeContext.Provider
      value={{
        data,
        setData,
        rootProblem,
        setRootProblem,
        handleDelete,
        handleEdit,
        addNudge,
        nudgeOptions,
        usersData,
        inviteDetails,
        setInviteDetails,
        handleInviteUser,
        inviteModalOpen,
        newEmail,
        setNewEmail,
        handleInviteModalClose,
        handleOpenSearchModal,
        addBehavior,
        selectedStakeholderIndex,
        setSelectedStakeholderIndex,
        isMapVisible,
        setIsMapVisible,
        fetchProblemData
      }}
    >
      {children}
    </NudgeContext.Provider>
  );
};
