import React from 'react'

const LoadingIndicator = () => {
  return (
    <div className="w-screen h-screen bg-primary flex  flex-col space-x-4 justify-center items-center text-5xl text-white ">
      
    Loading......
  </div>
  )
}

export default LoadingIndicator