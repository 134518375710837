import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Sidebar from "./SideBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { NavLink } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import profile from "../../Assets/Images/pprofile.png";

const TutorialHowTo = () => {
  const [openDialog, setOpenDialog] = useState(true); // Dialog state
  const [open, setOpen] = useState(true);
  const [isLastWeekExpanded, setIsLastWeekExpanded] = useState(false);
  const [isLastMonthExpanded, setIsLastMonthExpanded] = useState(false);
  const [contentWidth, setContentWidth] = useState("79.5%");

  useEffect(() => {
    setContentWidth(open ? "89.5%" : "96.5%");
  }, [open]);

  const handleCloseDialog = () => setOpenDialog(false);

  const recentNudges = Array(4).fill({});
  const lastWeekNudges = [
    { name: "Alex Carter", description: "Led a redesign increasing user satisfaction by 30%.", imgSrc: profile },
    { name: "Jamie Lane", description: "Improved project completion rate by 25%.", imgSrc: profile },
  ];
  const lastMonthNudges = [
    { name: "Jordan Smith", description: "Enhanced customer engagement by 15%.", imgSrc: profile },
    { name: "Taylor Kim", description: "Boosted team productivity by 20%.", imgSrc: profile },
  ];

  return (
    <div className="flex h-screen font-roboto bg-img text-base">
      {/* Dialog that opens on every page load */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          style: { borderRadius: 28, background: '#ece6f0', width: '450px', height: '200px' },
        }}
      >
        <DialogContent className="w-[450px] h-[250px] bg-[#ece6f0] rounded-[28px] p-6 flex flex-col justify-between font-roboto text-base">
          <div>
            <div className="text-[#1d1b20] text-2xl font-normal leading-loose">
             Coming Soon!!!!!
            </div>
            <div className="text-[#49454f] font-semibold leading-tight tracking-tight mt-4">
              This Page is under construction and will be available soon.
            </div>
          
          </div>
          <div className="w-full flex justify-end text-base">
            <button onClick={handleCloseDialog} className="px-3 py-2.5 rounded-full bg-bcolor text-white font-medium tracking-tight">
              OK!
            </button>
          </div>
        </DialogContent>
      </Dialog>

      <div style={{ width: open ? "22%" : "60px" }}>
        <Sidebar open={open} setOpen={setOpen} />
      </div>

      <div className="flex flex-col w-full" style={{ width: contentWidth }}>
        <div className="flex items-center h-[78px] bg-primary text-white font-medium pl-10 flex-shrink-0">
          <NavLink to="/tutorial">
            <button className="bg-bcolor rounded-full px-6 py-2.5 flex items-center text-white">
              <ArrowBackIcon /> <span className="ml-2">Back</span>
            </button>
          </NavLink>
          <h1 className="text-2xl ml-5 opacity-50">Tutorials and Resources</h1>
          <h1 className="text-2xl ml-2"> &gt; Successful Nudges</h1>
        </div>

        {/* Main Content */}
        <div className="w-full pl-5">
          <h1 className="text-[28px] mb-5 mt-10 pl-5 text-white">Recent Successful Nudges</h1>
          <div className="grid grid-cols-1 sm:grid-cols-4 gap-6 px-5 pb-5">
            {recentNudges.map((_, idx) => (
              <div key={idx} className="h-[284px] bg-[#fef7ff] rounded-[15px] relative overflow-hidden">
                <div className="w-[45px] h-[45px] bg-[#eaddff] rounded-[10px] shadow justify-center items-center -mt-5">
                  <div className="text-center text-[#49454f]/50 text-[11px] font-medium pt-1">
                    2 min
                    <br />
                    ago
                  </div>
                </div>
                <div className="w-full flex flex-col justify-center items-center space-y-2 -mt-3">
                  <img className="w-[140px] h-[140px] rounded-full shadow-md" src={profile} />
                  <div className="text-[#1d1b20] text-base font-normal">Chris Harper</div>
                  <div className="w-[90%] text-[#49454f] text-xs font-normal">
                    Successfully transformed a disorganized workflow into a structured process...
                  </div>
                  <div className="w-[95%] flex justify-end">
                    <button className="h-10 px-6 py-2.5 justify-center items-center inline-flex text-[#65558f] shadow-md rounded-full">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Scrollable Section */}
        <div className="overflow-y-auto flex-grow px-5">
          <div className="mt-4">
            <div
              onClick={() => setIsLastWeekExpanded(!isLastWeekExpanded)}
              className={`flex items-center cursor-pointer rounded-lg px-4 py-3 mb-4 ${
                isLastWeekExpanded ? "bg-bcolor text-white" : "bg-[#EADDFF] text-black"
              }`}
            >
              <div className={`w-10 h-10 rounded-full flex items-center justify-center ${isLastWeekExpanded ? "bg-white text-black" : "bg-bcolor text-white"}`}>
                {isLastWeekExpanded ? <ExpandLessIcon /> : <ChevronRightIcon />}
              </div>
              <div className="ml-4 flex-grow">
                <h1>Successful Nudges in the Last Week</h1>
                <h1>Desc</h1>
              </div>
            </div>

            {isLastWeekExpanded && (
              <div className="space-y-4 pl-10">
                {lastWeekNudges.map((nudge, index) => (
                  <div key={index} className="flex items-center gap-4 bg-[#d0bcff] p-3 rounded-md">
                    <img src={nudge.imgSrc} alt={`${nudge.name}'s profile`} className="w-10 h-10 rounded-full bg-[#eaddff]" />
                    <div className="flex-grow">
                      <div className="text-[#1d1b20] text-base font-semibold">{nudge.name}</div>
                      <div className="text-[#4a4459] text-sm">{nudge.description}</div>
                    </div>
                    <div className="text-[#49454f] text-[11px] font-medium">Read more...</div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="mt-4">
            <div
              onClick={() => setIsLastMonthExpanded(!isLastMonthExpanded)}
              className={`flex items-center cursor-pointer rounded-lg px-4 py-3 mb-4 ${
                isLastMonthExpanded ? "bg-bcolor text-white" : "bg-[#EADDFF] text-black"
              }`}
            >
              <div className={`w-10 h-10 rounded-full flex items-center justify-center ${isLastMonthExpanded ? "bg-white text-black" : "bg-bcolor text-white"}`}>
                {isLastMonthExpanded ? <ExpandLessIcon /> : <ChevronRightIcon />}
              </div>
              <div className="ml-4 flex-grow">
                <h1>Successful Nudges in the Last Month</h1>
                <h1>Desc</h1>
              </div>
            </div>

            {isLastMonthExpanded && (
              <div className="space-y-4 pl-10">
                {lastMonthNudges.map((nudge, index) => (
                  <div key={index} className="flex items-center gap-4 bg-[#d0bcff] p-3 rounded-md">
                    <img src={nudge.imgSrc} alt={`${nudge.name}'s profile`} className="w-10 h-10 rounded-full bg-[#eaddff]" />
                    <div className="flex-grow">
                      <div className="text-[#1d1b20] text-base font-semibold">{nudge.name}</div>
                      <div className="text-[#4a4459] text-sm">{nudge.description}</div>
                    </div>
                    <div className="text-[#49454f] text-[11px] font-medium">Read more...</div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorialHowTo;