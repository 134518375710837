import React, { useState, useEffect } from 'react';
import Slider from '@mui/material/Slider';

const KnowledgeScale = () => {
  const [selectedLevel, setSelectedLevel] = useState(
    () => parseInt(localStorage.getItem('familiarityLevel')) || 0
  );

  useEffect(() => {
    localStorage.setItem('familiarityLevel', selectedLevel);
  }, [selectedLevel]);

  const handleSliderChange = (event, newValue) => {
    setSelectedLevel(newValue);
  };

  const handleLabelClick = (index) => {
    setSelectedLevel(index);
  };

  return (
    <div className="w-full h-screen bg-[#00adb5] flex flex-col items-center justify-center space-y-[150px] text-base">
      {/* Main Content */}
      <div className="w-full text-center">
        <span className="text-black text-4xl font-normal font-['Roboto'] leading-[44px]">Select your </span>
        <span className="text-black text-[57px] font-bold font-['Roboto'] leading-[64px]">Agile Science familiarity</span>
        <span className="text-black text-4xl font-normal font-['Roboto'] leading-[44px]"> to personalize your experience!</span>
      </div>

      <div className="w-[100%] flex flex-col space-y-[70px] justify-between items-center">
        {/* Scale Labels */}
        <div className="flex justify-between w-[82%]">
          {[...Array(6)].map((_, index) => (
            <div 
              key={index} 
              className={`w-12 h-11 px-4 py-3 rounded-full flex justify-center items-center cursor-pointer ${
                index <= selectedLevel ? 'bg-[#65558f] text-white' : 'bg-white text-black'
              }`}
              onClick={() => handleLabelClick(index)}
            >
              <span className="text-center text-sm font-medium font-['Roboto'] leading-tight tracking-tight">
                {index}
              </span>
            </div>
          ))}
        </div>

        {/* MUI Slider */}
        <Slider
          value={selectedLevel}
          onChange={handleSliderChange}
          step={1}
          marks
          min={0}
          max={5}
          valueLabelDisplay="off"  // Disable hover value display
          sx={{
            width: '80%',
            '& .MuiSlider-track': {
              backgroundColor: '#65558f',
              height: '16px',
            },
            '& .MuiSlider-thumb': {
              width: '8px',
              height: '153px',
              borderRadius: '1px',
              backgroundColor: 'black',
              boxShadow: 'none',  // Remove shadow on hover
              '&:hover': {
                boxShadow: 'none',  // Keep shadow off on hover
              },
            },
            '& .MuiSlider-rail': {
              backgroundColor: 'white',
              height: '16px',
            },
            '& .MuiSlider-mark': {
                width: '4px',  // Adjust width for larger dots
                height: '4px', // Adjust height for larger dots
                backgroundColor: 'black',
                borderRadius:'36px',
                marginLeft: '-6px', // Center marks within the rail
              },
            '& .MuiSlider-markActive': {
              backgroundColor: '#65558f',
              height: '16px',
              
            },
          }}
        />
      </div>

      {/* Start Button */}
      <div className="absolute bottom-10 right-10">
        <button className="w-[169px] h-10 bg-[#65558f] rounded-full text-white font-medium">
          Start
        </button>
      </div>
    </div>
  );
};

export default KnowledgeScale;