import React, { useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";
import { Outlet } from "react-router-dom";
import Landing from "./Landing";
import { connect } from "react-redux";
import { setUser, setLoading, setUserId } from "../Store/Common/Slice";
import LoadingIndicator from "../Components/Common/LoadingIndicator";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_USER, CREATE_USER } from "../Graphql/User/Query"; // Ensure these imports are correct

const Loggedin = ({ user, loading, setUser, setLoading, setUserId }) => {
  const [getUserByEmail, { data: userData }] = useLazyQuery(GET_USER);
  const [createUser] = useMutation(CREATE_USER);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      setLoading(true);
  
      // Skip login actions if creatingNewUser flag is set
      if (localStorage.getItem("creatingNewUser") === "true") {
        setLoading(false);
        return;
      }
  
      if (authUser) {
        const email = authUser.email;
        const username = email.split("@")[0];
  
        // Check if user exists
        const { data } = await getUserByEmail({ variables: { email } });
        if (data && data.userByEmail) {
          const userId = data.userByEmail.id;
          setUser(email);
          setUserId(userId);
          localStorage.setItem("userId", userId);
          localStorage.setItem("username", data.userByEmail.username);
          localStorage.setItem("profilepic", data.userByEmail.profilepic);
        } else {
          // Create user if not exists
          const { data: newUser } = await createUser({ variables: { username, email } });
          const userId = newUser.createUser.id;
          setUser(email);
          setUserId(userId);
          localStorage.setItem("userId", userId);
          localStorage.setItem("username", newUser.createUser.username);
        }
  
        localStorage.setItem("from", "false");
      }
      setLoading(false);
    });
  
    return () => unsubscribe();
  }, [getUserByEmail, createUser, setUser, setLoading, setUserId]);

  if (loading) {
    return <LoadingIndicator />;
  }

  return <div>{user ? <Outlet /> : <Landing />}</div>;
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
});

const mapDispatchToProps = {
  setUser,
  setLoading,
  setUserId,
};

export default connect(mapStateToProps, mapDispatchToProps)(Loggedin);
