import React, { useState, useEffect } from "react";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Custom content for the toast, including the "Okay" button
const CustomToastContent = ({ message, closeToast }) => (
  <div className="flex flex-col items-end justify-end w-full h-full font-roboto">
    <p className="mb-6 text-[16px] ">{message}</p>
    <button
      onClick={closeToast}
      className="h-10 bg-bcolor rounded-full px-6 py-2.5 text-white font-medium flex items-center justify-center"
      style={{
        backgroundColor: "#6750A4",
        borderRadius: "24px",
        color: "#fff",
        fontSize: "16px",
        fontFamily: "Roboto, sans-serif",
      }}
    >
      Ok
    </button>
  </div>
);

const ToastAlert = () => {
  const [isToastVisible, setIsToastVisible] = useState(false);

  useEffect(() => {
    // Set up a listener for toast visibility changes
    const handleToastChange = (payload) => {
      setIsToastVisible(payload.status === "added");
    };

    // Add listener
    toast.onChange(handleToastChange);

    return () => {
      // Manually update visibility to remove overlay after cleanup
      setIsToastVisible(false);
    };
  }, []);

  return (
    <>
      {/* Conditionally render the blurred overlay */}
      {isToastVisible && (
        <div className="fixed inset-0 bg-white bg-opacity-70 backdrop-blur-[2px] z-40"></div>
      )}

      <ToastContainer
        position="top-center"
        autoClose={false}
        hideProgressBar
        closeOnClick={false}
        rtl={false}
        closeButton={false}
        draggable={false}
        pauseOnHover={false}
        theme="light"
        transition={Bounce}
        toastStyle={{
          maxWidth: "1000px",
          maxHeight: "600px",
          backgroundColor: "#ece6f0",
          borderRadius: "28px",
          padding: "20px",
          color: "#000",
          fontSize: "16px",
          fontFamily: "Roboto, sans-serif",
          zIndex: "50", // Ensures it appears above the overlay
        }}
        className="fixed inset-0 flex items-center justify-center z-50" // Center the container
      />
    </>
  );
};

// Modified showToast function to use CustomToastContent
export const showToast = (message) => {
  toast(
    ({ closeToast }) => (
      <CustomToastContent message={message} closeToast={closeToast} />
    ),
    {
      icon: false, // Remove icons
    }
  );
};

export default ToastAlert;